:root{
  --inputbg:#292929;
  --modalbg: #525252;
}
body{
  color: #fff;
  background-color: #121212;
  font-size: 12px;
}


/* body.modal-open {
  overflow: hidden;
  position: fixed;
} */

/* .tooltip{
  position: absolute !important;
  right: 0 !important;
  bottom: 30% !important;
} */

/* .tooltips{
  position: relative;
}
.custtooltip{
  position: absolute;
  bottom: -25px;
  background-color: #000;
  padding: 15px;
} */
.example-custom-input{
  background: url("../../assets/images/Background.png") no-repeat;
  background-size: 15px;
  width: 240px;
  height: 24px;
  background-position: bottom 5px right 2%;
  cursor: pointer !important;
}
.drree{
  padding: 0px 40px;
}
.uls li:before {
  /* background-color: #fff; */
  border-radius: 50%;
  box-shadow: 0 5px 15px 0 rgba(0,0,0,.05);
  content: url("../images//tick.png");
  height: 20px;
  left: 0;
  line-height: 30px;
  position: absolute;
  text-align: center;
  width: 20px;
}
.uls{
  font-size: 16px;
  padding-left: 15px !important;
}
.uls li{
  margin-top: 10px;
  line-height: 20px;
}
@media only screen and (max-width: 500px) {
  .drree{
    padding: 0px 15px;
  }
  .uls li {
    font-size: 14px;
   }
}
.MuiPopover-paper{
  min-width: unset !important;
}

.tooltip-inner {
  color: #fff !important;
  background: black !important;
  padding: 6px !important;
  font-size: 12px !important;
}
.bs-tooltip-bottom, .arrow::before,
        .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
            border-bottom-color: black !important;
        }
        .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
          border-top-color: black !important;
        }

.font-12 *{
  font-size: 12px;
}
.gap-1 {
  gap: .25rem!important;
}
.gap-2 {
  gap: .5rem!important;
}
.gap-3 {
  gap: 0.25rem!important;
}
.gap-4 {
  gap: 1.5rem!important;
}
.gap-5 {
  gap: 3rem!important;
}

.w-full{
  width: 100%;
}
.f-12{
  font-size: 12px;
}
p{
  margin-bottom: 0;
}
ul li{
list-style-type: none;
}
.nounder{
  text-decoration: none;
}
.light_theme p{
  color: #fff;
}
.light_theme hr{
  background: #9b9b9b !important;
}
.dropdown-divider {
  border-color: #bababa;
  margin: 0;
}
.fa, .far, .fas {
  font-family: Font Awesome\ 5 Free !important;
}
.jc-between{
  justify-content: space-between;
}
.jc-around{
  justify-content: space-around;
}
.flex-1 > *{
  flex:1
}
.text-orange{
  color: #f05824;
}
.bg-orange{
  background-color: #f13a0b ;
  color: #fff;
}
.text-green{
  color: #009345;
}
.icon-50 img {
  width: 25px;
}
.icon {
  width: 42px;
  border-radius: 5px 0 0 5px;
  padding: 7px;
}
.smallicon{
  width: 20px;
  filter: invert(1);
}
.label{
  color: #fff;
}
.btn-outline-secondary {
  color: #6c757d !important;
  border-color: #6c757d !important;
}
button.btn.graybtn {
    background: gray;
    padding: 0px 16px 0 16px;
    height: 25px;
 
    color: #fff;
    font-size: 12px;
}
.graybtn:hover {
  background-color: #f13a0b;
}

.graybox{
  background-color: #525252;
  border-radius: 3px;
}
.modalbg{
  background-color:#32323236
}
.darkbox{
  background-color: #292929;
}
.br-4{
  border-radius: 4px;
}
.darkbox{
   background: #292929;
}
.darkbox.blur_darkbox{
  background: #ececec1f !important;
}
.modalbg .modal-content{
  background-color: var(--modalbg);
}
.graybtn{
  background-color: #4a4a4a;
  border: 0;
  padding: 2px 2px;
  margin-right: 3px;
  border-radius: 3px;
}
.graybtn img {
  width: 17px;
  filter: invert(0.9);
}
.bluebtn{
  border: 0;
  padding: 2px 2px;
  margin-right: 3px;
  border-radius: 3px;
}
.bluebtn.orange{
  background-color: #f05824;
}
.bluebtn.green{
  background-color: #009345
}

.bluebtn.orange:hover{
  background-color: #ee4207;
}
.bluebtn.green:hover{
  background-color: #025127;
}
.bluebtn img {
  width: 17px;
  filter: invert(0.9);
}
table label{
  font-size: 12px;
}
.h-200.darkbox{
  height: 132px;
  overflow-y: auto;
  scrollbar-color: #63b2ff #c2d2e4;
scrollbar-width: thin;
}
#header{
  height: 50px;
}
#base{
display: flex;
}
#sidebar{
  width: 378px;
  background-color: #292929;
  height: calc(100vh - 70px);
  transition: 0.2s;
  border-radius: 3px;
}
.sidebarcont{
  position: relative;
  height: calc(100% - 43px);
  width: 378px;
}
#sidebar.mini{
  width: 40px;
  transition: 0.2s;
}
.toggle button.btn.btn-link.icon-50 img {
  filter: invert(0.8);
}
#cont{
  width: 100%;
  height: calc(100vh - 50px);
}
.modal-content{
  background-color: #000;
}
.btn.green-btn {
  background: #00e27b;
  height: 25px;
  line-height: 1;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btn.red-btn {
  background: #f05824;
  height: 25px;
  line-height: 1;
  color: #fff;
  display: inline-block;
  align-items: center;
  justify-content: center;
  border:1px solid #f05824;
}
.btn.red-btn:hover {
  background: transparent;
  height: 25px;
  line-height: 1;
  color: #f05824;
  display: inline-block;
  align-items: center;
  justify-content: center;
}

.btn.red-btn.red-btn-dele
{
  background: #f05824;
  height: 25px;
  line-height: 1;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border:1px solid #f05824;
}
.btn.red-btn.red-btn-dele:hover
{
  background: transparent;
  height: 25px;
  line-height: 1;
  color: #f05824;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btn.gray-btn{
  height: 25px;
  line-height: 1;
  color: #fff;
  display: inline-block;
  align-items: center;
  justify-content: center;
}
.whishlitsearch input {
  background: #171717;
  border-color: transparent;
  height: 26px;
  font-size: 12px;
}
.whishlitsearch input::placeholder {
  font-size: 12px;
}
.whishlitsearch span, .whishlitsearch input {
  font-size: 11px !important;
}
.whishlitsearch span {
  background: transparent;
  border-color: transparent;
  color: #fff;
}
.drops.d-block {
  position: absolute;
  background: #1a1a1a;
  top: 27px;
  right: -37px;
}
.watchlist{
  background-color: #4a4a4a;
  border: 0;
  color: #fff;
  width: 100%;
  padding: 5px;
}
.watchlist:hover{
  background-color:#595959
}

.drop{
  background-color: var(--inputbg);
}

.label{
  width: 80px;
}
.flex1{
  flex:1
}
.nowrap{
  flex-wrap: nowrap;
  white-space: nowrap;
}

.font-12 .h4, .font-12 h4 {
    font-size: 1.5rem;
}
.font-12 .h5,.font-12 h5 {
  font-size: 1.25rem;
}
.trand-cont {
  width: 100%;
}
.bg-trans {	
  background: transparent !important;	
}
.lightgraybtn{	
  background:#6a6a6a;	
  border: 0;	
  padding: 2px 2px;	
  margin-right: 3px;	
  border-radius: 3px;	
}	
.lightgraybtn img{	
  width: 17px;	
  filter: invert(0.9);	
}
.tabinput input{	
  border: 0;	
}
.form-check input[type="radio"]{	
  left: 16px;	
}	
.darkselect {	
  background: #292929;	
  border-color: #292929;	
  font-size: 12px !important;	
  padding: 5px;	
  border-radius: 4px;	
  --webkit-appearance: auto;
}	
.custdrop .scrolablediv .dropdown-toggle{	
  margin: 0;	
}

/* NIVITHA CSS */
/* .btn-success,.btn-outline-success:hover
{
border-color:#009345 !important;
background-color: #009345 !important;
border-width: 1px !important;
border: 1px solid #009345 !important;
color: #fff !important;

}
.btn-outline-success,.btn-success:hover
{
border-color:#009345 !important;
background-color: transparent !important;
border: 1px solid #009345 !important;
color: #009345 !important;


} */

.btn-info,.btn-outline-info:hover
{
border-color:#2769ba !important;
background-color: #2769ba !important;
border-width: 1px !important;
border: 1px solid #2769ba !important;
color: #fff !important;


}
.btn-outline-info,.btn-info:hover
{
border-color:#2769ba !important;
background-color: transparent !important;
border: 1px solid #2769ba !important;
color: #2769ba !important;


}
.symbols_table th,.symbols_table td
{
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.symbols_table tr:nth-child(2) td
{
  color: #AAAAAA !important; 
}
.symbols_table tr:nth-child(2) td
{
  padding-bottom: 10px !important;
}
.h-table-big.darkbox{
  height: 200px;
  overflow-y: auto;
  scrollbar-color: #63b2ff #c2d2e4;
scrollbar-width: thin;
}
.symbols_table td:nth-child(2),.symbols_table td:nth-child(3),
.symbols_table td:nth-child(6),.symbols_table td:nth-child(7),
.symbols_table td:nth-child(8),.symbols_table td:nth-child(9),
.symbols_table th:nth-child(2),.symbols_table th:nth-child(4)
{
  background-color: #525251 !important;
}
.text-success
{
  color:#00e27bf5 !important;

}
.text-danger
{
  color:#f05824 !important;
}
.chart_cont_cent_div
{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.label_per_txt
{
  font-size: 12px !important;
}
.selct_max_widt_sm
{
  max-width: 80px !important;
}
.detail_tab_height .graytab
{
  height: calc(100% - 50px);
}
.detail_tab_height .graytab .tab-content,.detail_tab_height_1 .graytab,.detail_tab_height_1 .tab-content
{
height:100% !important;
}
.detail_tab_height_1 .darkbox
{
  height: calc(100% - 23px);
}
.btn_tab_pos
{
  position: absolute;
  right: 15px;
  top: 45px;
}
.btn_only_tab
{
  background-color: #525252 !important;
  border-radius: 3px !important;
  border: 1px solid #6d6969 !important;
  color:#fff !important;
  padding: 4px 10px;

}

.bg_trans_tab .tab-content
{
  background: transparent !important;
}
.mt-5-det
{
  margin-top: 2.5rem !important;
}
.flex_widh_min
{
min-width: 165px;
}
.graph_desc_data
{
  font-size: 13px !important;
}
.tab_style_inner_grey
{
  border:1px solid #6d6969 !important;
  max-width: max-content !important;
  padding-top: 0px !important;
  /* position: absolute; */
}
.tab_style_inner_grey .nav-link
{
  margin-bottom: 0px !important;
}
.tab_style_inner_grey .nav-link:last-child
{
margin-right: 0px !important;
}
.gray_tab_marg_1.h-100 .tab-content
{
  height: calc(100% - 50px);
}
.boxstyles .graytab,.boxstyles .graytab .tab-content,.tab_cont_box_border
{
  border-radius: 3px !important;
}
.gray_tab_marg_1 .tab-content
{
margin-top: 1px !important;
border-radius: 0px 3px 3px 3px !important;
}

.tab_cont_box_border
{
  border-radius: 3px !important;
}
.sybm_tab_div
{
padding: 20px 20px;
}
.sybm_tab_div p
{
  font-size: 13px !important;
  margin-bottom: 10px !important;
}
.inner_box_bg
{
  background-color: #292929 !important;
  background: #292929 !important;
  margin: 5px !important;

}
.darkbx_bg_new.darkbox
{
  background-color: #525252 !important;
  background: #525252 !important;
  border-radius: 3px !important;

  
}
.w-full.wfull_new
{
  background: #525252 !important;
  border-radius: 3px !important;
  width: calc(100% - 383px) !important;

}
.boxstyles .symbolTab_list small
{
font-size: 90% !important;
}

.symbolTab_list li
{
  border-radius: 0px !important;
}
.inpt_grp_equity
{
  background-color: var(--inputbg);
  border-radius: 5px;
  height: 25px;
}
.inpt_grp_equity .input-group-prepend
{
  background-color: var(--inputbg);
  border-radius: 5px 0px 0px 5px;
  height: 25px;
  color:#fff;

}
.inpt_grp_equity .input-group-prepend .input-group-text
{
  color:#fff !important;

}
.inpt_grp_equity input
{
  height: 25px;

}
.max_he_ddrp_me
{
  max-height: 200px !important;
  overflow-y: auto;
  scrollbar-color: #63b2ff #c2d2e4;
scrollbar-width: thin;
}
.copy_icon_btn_side .fa
{
  margin-right: 15px !important;
}
.main_div_col.shrink_div .w-full.calc_width
{
  width: calc(100% - 10px) !important;
}
.bottom-nav-mob
{
  position: absolute;
    width: 100%;
    bottom: 0;
}
.pr-small
{
  padding-right: 0.25rem !important;
}
.text-silver-sm
{
  color: rgb(255, 255, 255);
  font-size: 12px !important;
  text-align: center;
}
.text-grey-sm
{
  color: #ffffff66 !important;
  font-size: 12px !important;
  text-align: center;
}

.graph_1
{
  width:3px;
  height: 6px;
  background-color: #525252;
  margin-right: 3px;
}
.graph_2
{
  width:3px;
  height: 9px;
  background-color: #525252;
  margin-right: 3px;

}
.graph_3
{
  width:3px;
  height: 12px;
  background-color: #525252;
  margin-right: 3px;

}
.graph_1.yellow
{
  background-color: #ffcc00;
}
.graph_2.yellow
{
  background-color: #ffcc00;
}
.graph_3.yellow
{
  background-color: #ffcc00;
}
.flag_width
{
  max-width: 15px;
}
.calendar_accordion:hover
{
  background-color: rgb(54, 54, 54);
}
.acc_cal_desc
{ color: rgb(255, 255, 255) !important;
  font-size: 12px !important;
}
.calendar_accordion button
{
background-color: transparent !important;
padding: 0px !important;
width: 99.2%;
}
.calendar_row .calendar_accordion button
{
  width: 96% !important;

}
.calendar_accordion .card-header
{
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-top: 0px !important;
}
.calendar_accordion .card
{
background-color: transparent !important;
border-radius: 0px !important;
margin: 0px !important;
box-shadow: 0 0px 0px 0 rgb(0 0 0 / 14%) !important;

}
.graytab .calendar_accordion a
{
  color:#2769ba !important;
  background-color: transparent !important;
  padding: 0px !important;
}

.graytab .calendar_accordion a:hover
{
  color:#fff !important;
  background-color: transparent !important;
}
.row_rate .col-3
{
  border-right: 1px solid #9b9b9b63;
}
.row_rate .col-3:last-child
{
  border-right: none !important;
}
.minh_22
{
  min-height: 22px;
}
.calendar_accordion
{
  border-bottom: 1px solid #9b9b9b63;
}
.darkbox.h-100-percent
{
  /* height: 100% !important; */
  max-height: calc(100vh - 146px);
    overflow-y: auto;
    scrollbar-color: #63b2ff #c2d2e4;
scrollbar-width: thin;
}
.tab_footer_dom .btn
{
width: 100% !important;
}
.f-11
{
  font-size: 11px !important;
}
.h-100-calc
{
  max-height: calc(100vh - 268px);
  min-height: calc(100vh - 268px);
  overflow-y: auto;
  scrollbar-color: #63b2ff #c2d2e4;
scrollbar-width: thin;
}
.h-100-calc-new
{
  max-height: calc(100vh - 259px);
  min-height: calc(100vh - 259px);
  overflow-y: auto;
  scrollbar-color: #63b2ff #c2d2e4;
scrollbar-width: thin;
}
.h-100-calc-full
{
  max-height: calc(100vh - 139px);
  min-height: calc(100vh - 139px);
  overflow-y: auto;
  scrollbar-color: #63b2ff #c2d2e4;
scrollbar-width: thin;
}
.trand-cont .shrinktop .chartcontainer
{
  height: calc(100vh - 328px);
}
/* .trand-cont .chartcontainer {
  height: calc(100vh - 257px);
} */
.trand-cont .chartcontainer {
  height: calc(100vh - 197px);
}
.min_w_input
{
  min-width: 150px;
}
.slect_width_min
{
  max-width: 120px;
  padding: 2px 2px !important;
  font-size: 11px !important;
}
.whishlitsearch input,
.whishlitsearch .input-group-text.search_text
{
  background-color: #393d41 !important;
}

@media (min-width: 992px)
{
.modal_big .modal-dialog.modal-lg {
    max-width: 906px !important;
}
}
.w-full.calc_width
{
  width: calc(100% - 382px);
}
.btn.closebtn 
{
  padding-left: 5px !important;
    padding-right: 5px !important;
}
.withot_wrap
{
  flex-wrap: nowrap !important;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-color: #63b2ff #c2d2e4;
scrollbar-width: thin;
}
.withot_wrap .nav-link 
{
  white-space: nowrap;
}
.tradePages th,.tradePages td,.modalbg th,.modalbg td{
  white-space: nowrap;
  padding: 2px 5px;
}
.symbolTab_list
{
  margin-bottom: 0px !important;
}
.coinlists ul
{
  margin-bottom: 2px !important;
}
.withot_wrap_blk.nav-tabs
{
  display: inline-block !important;
  overflow-x: auto !important;
  width: 100% !important;
  white-space: nowrap !important;
  overflow-y: hidden !important;
  min-height: 40px !important;
  scrollbar-color: #63b2ff #c2d2e4;
scrollbar-width: thin;
}
.withot_wrap_blk .nav-link
{
display: inline !important;
}
.pos_copy_flat.card
{
  top: -20px !important;
}
.pos_copy.card
{
  top: 0px !important;
}
.sidebar_icon_btn.btn-primary
{
    padding: 0 !important;
    background-color: #2769ba !important;
    background: #2769ba !important;
    max-width: 39px !important;
    border-color: #3b3b3b !important;
    color: #fff;
    margin: 0;
    border-radius: 0.2rem !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
}
.sidebar_icon_btn .fa
{
  font-size: 14px !important;
}
.sidebar_icon_btn:hover .fa
{
  color:#fff !important;
}
.copy_icon_btn_side
{
  min-height: 44px !important;
  padding-left: 10px !important;
}
/* RESPONSIVE CSS */
@media only screen and (max-width:767px)
{
  .mob_res_sm,.flex_mob_blk_res
  {
    display: block !important;

  }
  .tradePages .d-flex.gap-3
  {
    display: block !important;
  }
  #sidebar
  {
    max-width: 100% !important;
    width: 99% !important;
    margin-right: 0.5% !important;
    margin-left: 0.5% !important;
  }
  .mob_res_sm .min_w_input,.mob_res_sm select
  {
    margin-bottom: 5px !important;
  }
  .slect_width_min
  {
    margin-right: 5px !important;
  }
  .sidebarcont
  {
    max-width: 100% !important;
    width: 100% !important;
  }
  .w-full.calc_width,.bottom-nav
  {
    width: 99% !important;
    margin-right: 0.5% !important;
    margin-left: 0.5% !important;
  }
  .block_mob_res
  {
    display: block !important;
  }
  .pl_mob_res
  {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .col-md-8.pr-small
  {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .modalbg .sellbtn, .modalbg .buybtn
  {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .mb-mob-ip
  {
    margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
  }
  .block_res_mob_btn
  {
    display: block !important;
  }
  .flex_widh_min
  {
    padding-left: 0.25rem !important;
  }
  .block_mob_res_det,.flex_widh_min
  {
    display: block !important;
  }
  .block_mob_res_det .text-center
  {
    text-align: left !important;
  }
  .block_mob_res_det .pl-3
  {
    padding-left: 1.5rem !important;
  }
  .block_mob_res_det .btn 
  {
    margin-left: 20px !important;
  }
  .block_mob_res_det .btn-outline-success 
  {
    max-height: 20px!important;
    margin-left: 0px !important;
  }
  .back_bt_det
  {
    margin-top: 15px !important;
  }
  .mt-5-det
  {
    margin-top:0px !important;
  }
  .detail_tab_height .graytab
  {
    height: unset !important;
    margin-bottom: 10px !important;
  }
  .btn_tab_pos
  {
    right: 30px !important;
    top: 45px !important;
  }
  .mb-sum-mon
  {
    margin-bottom: 2px !important;
  }
  .selct_max_widt_sm
  {
    max-width: 100% !important;
  }
  .mb-roi-mon
  {
    margin-bottom: 10px !important;
  }
  .block_mob_res_det .gap-4
  {
    gap:0.5rem !important;
  }
  .block_mob_res_det span,.block_mob_res_det h5,.block_mob_res_det p{
    font-size: 13px !important;
    margin-bottom: 5px!important;
    display: block;
    margin-top: 5px !important;
  }
}

@media only screen and (min-width:768px) and (max-width:991px)
{
  .h5-align h5{
    font-size: 14px !important;
  }
  .w-full.calc_width .h-100-calc-full
  {
    min-height: calc(100vh - 60px) !important;
    max-height: calc(100vh - 60px) !important;
    overflow-y: auto;
    scrollbar-color: #63b2ff #c2d2e4;
scrollbar-width: thin;
  }
  .block_mob_res
{
  display: block !important;
}
.modal_big .mob_res_sm
{
  display: block !important;
}
#uncontrolled-tab-example-tabpane-calendar .darkbox.h-100-percent
{
  height: calc(100% - 40px) !important;
  max-height: calc(100% - 40px);
}
.block_mob_res_det .btn-outline-success 
{
  max-height: 20px!important;
  margin-left: 0px !important;
}
.back_bt_det
{
  margin-top: 7px !important;
}
.mt-5-det
{
  margin-top:0px !important;
}
.detail_tab_height .graytab
{
  height: unset !important;
  margin-bottom: 10px !important;
}
.btn_tab_pos
{
  right: 30px !important;
  top: 45px !important;
}
.mb-sum-mon
{
  margin-bottom: 2px !important;
}
.selct_max_widt_sm
{
  max-width: 100% !important;
}
.mb-roi-mon
{
  margin-bottom: 10px !important;
}
}
@media only screen and (min-width:992px) and (max-width:1199px)
{
  .w-full.calc_width .h-100-calc-full
  {
    min-height: calc(100vh - 148px) !important;
    max-height: calc(100vh - 148px) !important;
    overflow-y: auto;
    scrollbar-color: #63b2ff #c2d2e4;
scrollbar-width: thin;
  }
  /* .new-drop-bx .calendar_accordion
  {
    max-height: 345px;
    overflow-y: auto;
  } */
  .slect_width_min
  {
    max-width: 115px !important;
  }
}
@media only screen and (min-width:768px) and (max-width:1199px)
{
  .tradePages .d-flex.gap-3
  {
    display: block !important;
  }
  #sidebar
  {
    max-width: 100% !important;
    width: 99% !important;
    margin-right: 0.5% !important;
    margin-left: 0.5% !important;
  }
  .mob_res_sm .min_w_input,.mob_res_sm select
  {
    margin-bottom: 5px !important;
  }
  .slect_width_min
  {
    margin-right: 5px !important;
  }
  .sidebarcont
  {
    max-width: 100% !important;
    width: 100% !important;
  }
  .w-full.calc_width {
    width: 100% !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
    margin-top: 46px !important;
}
.w-full.calc_width .pr-small
{
  padding-left: 4px !important;
}
.w-full.calc_width .pl_mob_res
{
  padding-right: 4px !important;
}
.w-full.calc_width .pl_mob_res .graybox 
{
height: 100% !important;
}
.w-full.calc_width .pl_mob_res .graybox .tab-content
{
  height: calc(100% - 40px) !important;

}
.w-full.calc_width .pl_mob_res .graybox .tab-content .tab-pane
{
  height: 100% !important;
}

/* .w-full.calc_width .h-100-calc-full
{
  min-height: calc(100% - 40px) !important;
  max-height: calc(100% - 40px) !important;

} */
.innerdomtab
{
  height: calc(100% - 120px);
}
.innerdomtab .d-flex.gap-1
{
  height: 100% !important;
}
.w-full.calc_width  .h-100-calc
{
  min-height: calc(100% - 0px) !important;
  max-height: calc(100% - 0px) !important;
}

.f-11.btn
{
  font-size: 9px !important;
}


.market_hours_text .col-md-3
{
display: flex;
}
}

@media only screen and (min-width:1200px) and (max-width:1300px)
{
  .block_mob_res.mob_res_scrol_blk
  {
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-color: #63b2ff #c2d2e4;
scrollbar-width: thin;
  }
  .block_mob_res.mob_res_scrol_blk .themeinput 
  {
    min-width: 100px;
  }
  .f-11.btn
{
  font-size: 9px !important;
}
.input_multi_rade input
{
  max-width: 105px !important;
}
}

@media only screen and (max-width:767px)
{
  .shrink_div.main_div_col .sidebarcont
  {
    position: fixed;
    width: 300px !important;
    z-index: 1;
    display: block !important;
    background: #121212;
    top:55px !important;
  }
  #sidebar 
  {
    width: 0px !important;  
    height: 0px;
  }
  .sidebarcont
  {
    display: none !important;
  }

  .shrink_div.main_div_col #sidebar {
   
    width: 0px !important;  
    height: 0px;
}
  .btn_toggle_blue
  {
    background-color: #2769ba !important;
    border-color: #3b3b3b !important;
    color: #fff !important;
    position: fixed;
    z-index: 1200;
    top: 45px !important;
    left: -5px !important;
    padding: 5px 10px !important;
  }
  .shrink_div.main_div_col .btn_toggle_blue
  {
    left:272px !important;
  }
  .main_div_col.shrink_div .w-full.calc_width
  {
    width: calc(100% - 0px) !important;
  }
  .shrink_div.main_div_col .sidebar_mob_con
  {
    display: none !important;
  }
  .bottom-nav
  {
    bottom:20px !important;
  }
  /* .shrink_div.main_div_col #sidebar
  {
    position: fixed;
    width: 0px !important;
    z-index: 1;
  }
  .btn_toggle_blue
  {
    background-color: #121212 !important;
    border-color: #3b3b3b !important;
    color: #fff !important;
    position: fixed;    
    z-index: 1200;
    top: 65px !important;
    right: 25px !important;
    padding: 5px 10px !important;
  }
  .shrink_div.main_div_col .sidebar_mob_con
  {
    display: none !important;
  }
  .shrink_div.main_div_col .w-full.calc_width
  {
    margin-top: 50px !important;
  } */
}

@media only screen and (min-width:768px) and (max-width:1199px)
{
  .bottom-nav
  {
    bottom:20px !important;
  }
  .shrink_div.main_div_col .sidebarcont
  {
    position: fixed;
    width: 300px !important;
    z-index: 1;
    display: block !important;
    background: #121212;
    top:55px !important;
  }
  #sidebar 
  {
    width: 0px !important;  
    height: 0px;
  }
  .sidebarcont
  {
    display: none !important;
  }

  .shrink_div.main_div_col #sidebar {
   
    width: 0px !important;  
    height: 0px;
}
  .btn_toggle_blue
  {
    background-color: #2769ba !important;
    border-color: #3b3b3b !important;
    color: #fff !important;
    position: fixed;
    z-index: 1200;
    top: 45px !important;
    left: -5px !important;
    padding: 5px 10px !important;
  }
  .shrink_div.main_div_col .btn_toggle_blue
  {
    left:272px !important;
  }
  .main_div_col.shrink_div .w-full.calc_width
  {
    width: calc(100% - 0px) !important;
  }
  .shrink_div.main_div_col .sidebar_mob_con
  {
    display: none !important;
  }
  /* .shrink_div.main_div_col .w-full.calc_width
  {
    margin-top: 50px !important;
  } */
}

@media only screen and (max-width:575px)
{
  .block_res_mob_btn
  {
    display: block !important;
  }
  .flex_widh_min
  {
    padding-left: 0.25rem !important;
  }
  .block_mob_res_det,.flex_widh_min
  {
    display: block !important;
  }
  .block_mob_res_det .text-center
  {
    text-align: left !important;
  }
  .block_mob_res_det .pl-3
  {
    padding-left: 1.5rem !important;
  }
  .block_mob_res_det .btn 
  {
    margin-left: 20px !important;
  }
  .block_mob_res_det .btn-outline-success 
  {
    max-height: 20px!important;
    margin-left: 0px !important;
  }
  .back_bt_det
  {
    margin-top: 15px !important;
  }
  .mt-5-det
  {
    margin-top:0px !important;
  }
  .detail_tab_height .graytab
  {
    height: unset !important;
    margin-bottom: 10px !important;
  }
  .btn_tab_pos
  {
    right: 30px !important;
    top: 45px !important;
  }
  .mb-sum-mon
  {
    margin-bottom: 2px !important;
  }
  .selct_max_widt_sm
  {
    max-width: 100% !important;
  }
  .mb-roi-mon
  {
    margin-bottom: 10px !important;
  }
  .block_mob_res_det .gap-4
  {
    gap:0.5rem !important;
  }
  .block_mob_res_det span,.block_mob_res_det h5,.block_mob_res_det p{
    font-size: 13px !important;
    margin-bottom: 5px!important;
    display: block;
    margin-top: 5px !important;
  }
}
@media only screen and (min-width:1200px)
{
  .sticky_sidebar #sidebar
  {
    position: sticky;
    top:60px;
    height: calc(100vh - 70px) !important;
  }
  .text-right-web-btn
  {
    text-align: left;
  }
}
@media only screen and (max-width:1199px)
{
  .text-right-web-btn
  {
    text-align: right;
  }
}
/* END RESPONSIVE CSS */
/* END NIVITHA CSS */

/* MANOJ CSS */
.custdacc.inneracc .far.fa-star{
  color: #fff;
}
.custdacc.inneracc .fas.fa-star{
  color: #fff;
}
.custdacc.inneracc .far.fa-star:hover .fa-star::before{
  color: #fff;
  background-color: #fff;
}
.starfont:hover .far.fa-star,
.starfont .fas.fa-star {
    display: none;
}
.starfont:hover .fas.fa-star {
    display: inline;
}

.custdacc.inneracc .stardropdown .dropdown-toggle:after{
  display: none;
}
.custdacc.inneracc .stardropdown button{
 padding: 0px;
 background: transparent;
}
.show>.btn.btn-success.dropdown-toggle{
  background: transparent;
}
.show.stardropdown>.btn.btn-success.dropdown-toggle{
  background: transparent;
  border-color: transparent;
}
.stardropdown .dropdown-menu{
 background: #3b3b3b !important;
}
.stardropdown .dropdown-menu .dropdown-item{
  color: #fff !important;
}
.darkselect.selctsm
{
  padding: 2px 5px !important;
  max-height: 24px !important;
}
.show.stardropdown>.btn.btn-success.dropdown-toggle:hover{
  background-color: transparent;
}
.stardropdown .dropdown-menu.show.dropdown-menu{
  width: auto;
}
.custdacc.inneracc .acc-item .stardropdown{
  margin-right: 5px;

}
.btn-success:not(:disabled):not(.disabled):active{
  background-color: transparent;
}
.custdacc.inneracc .acc-item .text-green{
  border: 1px solid #009345;
  height: 17px;
  width: 17px;
  line-height: 1.4;
  border-radius: 3px;
}
.custdacc.inneracc .acc-item .text-danger{
  border: 1px solid #f44336;
  height: 17px;
  width: 17px;
  line-height: 1.4;
  border-radius: 3px;

}
.custdacc.inneracc .acc-item .text-green-dot,.custdacc.inneracc .acc-item .text-red-dot{
  height: 8px;
  width: 0px;
  border-radius: 50%;
  display: inline-block;
}
.custdacc.inneracc .acc-item .text-green-dot{
  color:#009345 ;
  background-color: #009345;
}
.custdacc.inneracc .acc-item .text-red-dot{
  color:red ;
  background-color: red;
}
.custdacc.inneracc .acc-item .pricelearn{
  align-items: center;
  gap: 10px;
}
.custdacc.inneracc .acc-item .pricelearn .balance{
  margin-right: 24px;
  color: #009345;
}
.themetab.strategy .widthset{
 
  align-items: center;
}
.themetab.strategy .themeinput ,.themetab.strategy .themeselect{
  width: 214px;
}
.themetab.strategy .heading{
  font-weight: 600;
  
}
@media only screen and (min-width:320px) and (max-width:400px){
  .themetab.strategy .inpt_grp_equity{
    width: 150px;
  }
  .themetab.strategy .themeinput, .themetab.strategy .themeselect {
    width: 150px;
}
.edit_stra_modal_body p
{
font-size: 13px !important;
}
.input_gpr_equ_wa
{
  width: 150px !important;
}
.input_gpr_equ_wa .themeinput
{
width: 95px !important;
}

}

  .boxstyles .graytab .tab-content{
    background: #525251;
    padding: 5px !important;
    border-radius: 0 0 3px 3px;
  }
  .boxstyles .darkbox{
    background: #292929;
    padding: 5px;
  }
  .boxstyles .boxheadings{
    font-size: 12px;
  }
  .boxstyles .symbolTab_list .headings{
    font-weight: 500;
    font-size: 12px;

  }
/* END MANOJ CSS */

/* KR CSS */

.symbols_table td{
  text-align: end;
}
.symbols_table td:first-child{
  text-align: start;
}
.card_symbol_acc{
  padding: 0px 5px 0px 5px ;
    background-color: #525252 !important;
    border-color: #3b3b3b !important;
    text-align: left !important;
    color: #fff;
    border-radius: 3px
}

.symbolTab_list{
  color: #fff;
  list-style-type: none;
  

}
.symbolTab_list li{
  cursor: pointer;
  padding: 5px 0px 5px 5px;
  font-size: 12px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-align: left !important;
  border-bottom: .5px solid rgb(129, 129, 129);
}
.symbolTab_list li:last-child{
  border:none;
}
.symbolTab_list li:hover{
  background-color: rgb(66, 66, 66);
}
.whishlitsearch input,
.whishlitsearch .input-group-text.search_text
{
  background-color: #393d41 !important;
}
.symbol_tab_no_hover .calendar_accordion:hover
{
  background-color: transparent !important;
}

.green_dot{
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: lightgreen;
}
.market_hours_text{
  font-size: 12px;
}
.h-100-calc small{
  color: #fff;
}
.new_order_tab.themetab .nav-tabs .nav-item{
  padding: 5px !important;
}
.themetab .nav-tabs .nav-item:hover

{
  background-color: #525252 !important;
}

.calendar_accordion .sellbtn{
  padding: 8px 10px 0px 8px !important;
  /* background-color: #000 !important; */
}
.calendar_accordion .buybtn{
  padding: 8px 10px 0px 8px !important;
  /* background-color: #000 !important; */
}
.system_textarea{
  background-color: rgb(83, 83, 83);
  color: #fff;
  padding: 6px !important;
  border-radius: 3px;
}
.calendar_accordion .system_placeorderBtn{
  background-color: #00e27b !important;
  border: none !important;
  padding: 5px !important;
  font-size: 12px;
  border-radius: 6px !important;
}
.calendar_accordion .system_placeorderBtn.red{
  background-color: #f13a0b !important;
}
.calendar_accordion .system_limit_sellLimit,.system_limit_buyLimit{
  padding: 5px 10px 3px 8px !important;
}
.calendar_accordion .system_limit_buyLimit{
  padding: 5px 10px 3px 8px !important;
}

.calendar_accordion .system_limit_sellLimit,.system_limit_sellLimit{
  padding: 5px 10px 3px 8px !important;
}
.calendar_accordion .system_limit_buyLimit{
  padding: 5px 10px 3px 8px !important;
}
.font-09{
  font-size: 9px;
}
.light_theme .duration_text{
  font-size: 12px;
}
.graytab .tab-content{
  padding: 0 !important;
}

.graytab .market_down{
  padding: 0 !important;
}
.new-drop-bx input,.new-drop-bx select{
  background-color: rgb(83, 83, 83);
  border-color: #292929  !important;
}
.new-drop-bx input{
  border-color: #29292929 !important;
}
.new-drop-bx label{
  font-size: 12px;
} 


.new-drop-bx input[type="checkbox"]
{
  max-width: 13px !important;
  max-height: 13px !important;
  min-width: 13px !important;
  min-height: 13px !important;
}
.content_box{
  flex: 1;
}
.modal_align .text_align{
  
  white-space: nowrap;
  overflow: hidden;
  width: 180px;
  text-overflow: ellipsis;
  display: inline-block;
}
.input-group-h25{
  border-radius: 6px;
  padding: 0;
}
.input-group-h25 .input-group-prepend,.input-group-h25 .input-group-input{
  height: 25px !important;
  background-color: #292929 !important;
}
.input-group-h25 .input-group-prepend
{
  border-radius: 5px 0px 0px 5px !important;
}
.input-group-h25 .input-group-input
{
  border-radius: 0px 5px 5px 0px !important;
  
}
.readOnly{
  border-radius: 6px !important;
}



.h5-align h5{
  margin-bottom: 0 !important;
}
.star_align{
  margin-top: 2px;
}
@media  (1595px < width) {
  .symbol_tab small{
    font-size: 13px;
  }
 

}
@media only screen and (min-width:320px) and (max-width:448px){
  .modal_align .text_align{
  
    width: 99px !important;
  }

}

.filter_button{
 
  position: relative;
  
}

.filter_button_details .MuiSlider-root{
  padding: 6px 0px;
}
.copiers input{
  width: 50px;

}
/* END KR CSS */

.input_grp_dashboard
{
  border-radius: unset !important;
    border: unset !important;
    border-bottom: 1px solid #787272 !important;
    padding: unset !important;
}
.accountsetting .input_grp_dashboard input,
.input_grp_dashboard input
{
  border:none !important;
}
.cur_pointer_eye
{
  cursor: pointer;
}
.cur_pointer_eye .fa
{
  color: #63b2ff !important;
}


/* Fund account css */
.fundaccount .depositfirst .input-group {
  width: 100%;
  margin-left: unset;
  gap: 10px;
}

.fundaccount .depositthird  {
 flex-direction: column;
}
.fundaccount .depositthird .wallet_balance_div p  {
margin-bottom: 10px;
 }
 
 .fundaccount .qrsec img{
 max-width: 200px;
 min-width: 200px;
 }
 .fundaccount .qrsec{
  text-align: center;
 }

.fundaccount #nav-withdraw .depositfirst .input-group{
  gap: 0px;
}
.fundaccount #nav-withdraw .totalbal{
  text-align: center;
  background: #1b1a1a;
  padding: 10px;
}
.fundaccount #nav-withdraw .totalbal span{
 color: #fff;
}

.fundaccount   .dropdown .btn-addmoney {
  height: 35px;
  margin: 0px;
  width: 100%;
 
}
.fundaccount  #nav-withdraw  .depositfirst{
  align-items: flex-end;
}

/* .fundaccount  #nav-withdraw  .depositfirst.secrow .labelname{
  min-height: 35px;
 
} */

@media only screen and (max-width:991px){
  .fundaccount #nav-withdraw .depositfirst.secrow .labelname {
    min-height: unset;
}
}

/* @media only screen and (max-width:400px){
  .fundaccount #nav-deposit .depositsecond {
    justify-content: center;
}
} */

@media only screen and (max-width: 991px) and (min-width: 768px)
{
.fundaccount .depositfirst .input-group {
    width: 100% !important;
}
}
.fundaccount  #nav-deposit  .group{
  width: 100%;
 
}

.fundaccount  #nav-deposit .depositsecond{
  align-items: unset;
}
.fundaccount .dropdown .btn-addmoney:hover,
.fundaccount .dropdown .btn-addmoney:focus,
.fundaccount .dropdown .btn-addmoney:active{
  border: 1px solid transparent;
}

@media only screen and (min-width: 1201px){
.pos_copy_flat.card
{
  top: -40px !important;
}
.pos_copy.card
{
  top: 0px !important;
}

}

/* end Fund account css */


.myaccount .balancecard{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media only screen and (min-width:1200px) and (max-width:1400px)
{
  .block_mob_res.mob_res_scrol_blk
  {
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-color: #63b2ff #c2d2e4;
scrollbar-width: thin;
  }
  .block_mob_res.mob_res_scrol_blk .themeinput 
  {
    min-width: 100px;
  }
}
.react-tel-input .flag-dropdown {

  border-radius: 0px 0 0 0px !important;

}
.notificationDropdown .notifyreadall{
  text-align: right;
}
.notificationDropdown .notifyreadall button{
  background: transparent;
  color: #63b2ff;
  border: none;

}
.notificationDropdown .notifyreadall button:hover{

  color: #ffffff;


}
.light_theme .custom-file input, .light_theme .custom-file {
  height: 35px !important;
}.light_theme .custom-file-label {

  height: 35px !important;

}
.light_theme .custom-file-label::after {
  top: 0px !important;
}
.contact_form .form-control{
 color: #787272 !important;
}
.light_theme .settingsSelect .MuiInput-underline {

  background-color: transparent !important;
  border-color: #404040 !important;
  border-radius: 4px !important;
  color: #787272 !important;
  box-shadow: 0 0 0 0rem #fff !important;
  height: 35px !important;
  font-size: 12px !important;
  border: 1px solid #787272 !important;
  padding: 10px;
}
.contact_form .form-control {
  /* border: 1px solid #242827 !important; */
  background: transparent !important;
  color: #fff;
  min-height: 35px !important;
  border-radius: 4px;
  padding-left: 10px !important;
  padding-right: 10px !important;
  border-color: #404040 !important;
  background-color: transparent !important;
  border-color: #404040 !important;
  border-radius: 4px !important;
  color: #787272 !important;
  box-shadow: 0 0 0 0rem #fff !important;
  height: 35px !important;
  font-size: 12px !important;
  border: 1px solid #787272 !important;
  padding: 10px;
}
.form-control:disabled, .form-control[disabled] {
  background-color: #76767600 !important;
  color: white !important;
}
.light_theme .input-group-append .btnType1 {
  color: #a2abb1 !important;
  background: transparent !important;
  text-align: right;
  justify-content: flex-end !important;
  border: 1px solid #787272 !important;
  
}
.light_theme .custom-file input, .light_theme .custom-file {
  height: 35px !important;
  background-color: transparent !important;
  border-color: #404040 !important;
  border-radius: 4px !important;
  color: #787272 !important;
  box-shadow: 0 0 0 0rem #fff !important;
  height: 35px !important;
  font-size: 12px !important;
  border: 1px solid #787272 !important;
  padding: 10px;
}
.contact_form textarea.form-control {


  padding: 5px;
}
.react-tel-input .flag-dropdown {
  border-radius: 0px !important;

  border: 1px solid #787272 !important;
}
.tradechart.copymain svg{
 background-size: 100% 100%;
}
.tradechart.copymain {
  background: transparent !important;
}
.tradechart.copymain div#apexchartsbasic-bar {
  margin: auto;
  background: #000;
  padding: 0px;
  min-height: 138px !important;
  max-height: 138px !important;
}
button.strategydrop {
border: none;
border-radius: 0.2rem;
}
.informationmodal input[type="radio"],.informationmodal input[type="checkbox"] {

  margin-top: -2px;
}

.strategydrop .fa-chevron-down:before {
  content: "\f078";
  font-size: 8px;
}
.btn.green-btn:hover{
  border:1px solid #009345;
  background-color: transparent;
  
  color: #009345;

}
.btn.btn-outline-success:hover{
  background-color: #009345;
  color: #fff;
}
.btn.btn-outline-secondary:hover{
  background-color:   #6c757d;
  color: rgb(221, 221, 221) !important;
}
.btn.bg-dark{
  border:1px solid transparent;
}
.btn.bg-dark:hover{
  background-color: transparent !important;
  border: 1px solid #343a40 ;
  color: #fff;
}
/* .btn.btn-success:focus, .btn.btn-success.focus, */
 .btn.btn-success:hover {

  background-color: #aeaeae;

}
.copytrade .cursor:hover{
  background-color:#aeaeae !important ;
}
.tradechart.copymain div#apexchartsbasic-bar svg g {
  transform: matrix(1.1, 0, 0,1.2, 0, 0);
}
#uncontrolled-tab-example-tabpane-history .text-light.btn:hover{
background-color: #2769ba !important;
color: #fff;
}
#uncontrolled-tab-example-tabpane-transactions .text-light.btn:hover{
  background-color: #2769ba !important;
  color: #fff;
  }
#uncontrolled-tab-example-tabpane-orders .text-light.btn:hover{
  background-color: #2769ba !important;
  color: #fff;
  }
.text-light.btn.btn-success:hover{
  background-color: #999999 !important;
  color: #fff;
  } 
  .calendar_accordion .system_placeorderBtn{
    border: 1px solid transparent !important;
  }
.calendar_accordion .system_placeorderBtn:hover{
    background-color: transparent!important;
    border: 1px solid #00e27b  !important;
    color: #00e27b !important;
  }
  .calendar_accordion .system_placeorderBtn.red:hover{
    background-color: transparent!important;
    border: 1px solid #f13a0b  !important;
    color: #f13a0b !important;
  }
.system_textarea:focus{
  background-color: #292929;
}
/* .calendar_accordion .buybtn:hover{
 
  background-color: #000 !important;
} */
.tab_footer_dom .text-light.btn:hover{
  background-color:#2769ba;

}
.modal-content .themetab .nav-tabs .nav-item.active {
  background: #2769ba;

}
.modal-content .themetab .nav-tabs .nav-item:hover {
  background: #2769ba !important;

}
.infomodal .darkbox p{
  color: #e0e0e0;

}
.infomodal .btn.graybtn:hover{
  background: #f13a0b !important;
  border: 1px solid #f13a0b;
  color: rgb(201, 201, 201);
} 
.infomodal .themetab .btn.sellbtn:focus{
  background-color: black !important;
  
}
.bal_sec_trade_da
{
  padding: 0px !important;
}

.tradedashboard .card.mt-5-card
{
  margin-top: 2rem !important;
}


.tradedashboard .container .fomt_check_heig_20 input {
height: 20px !important;
}
.link_inside_table
{
  text-decoration: underline !important;
}
.link_inside_table:hover
{
  color:#fff !important;
}
.countr_drop_flex
{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-color: #787272 !important;
  border-bottom-width: 1px !important;
  border-bottom:1px solid #787272 !important;
  border-radius: 0px !important;
  font-size: 14px !important;
}
.countr_drop_flex .country_dropdown 
{
border:none !important;
font-size: 15px !important;
color:#9f9f9f !important;
padding-left: 0px !important;
}
.countr_drop_flex span
{
  padding-right: 8px !important;
}
.settingsSelect.settingsSelect_bor_bot_only .MuiInput-underline
{
border-top: none !important;
border-left: none !important;
border-right: none !important;
border-radius: 0px !important;
padding-left: 0px !important;
font-size: 15px !important;
color:#9f9f9f !important;
}
.btn_add_acc_blue
{
  padding-left: 20px !important;
  padding-right: 20px !important;
  margin-bottom: 30px !important;
}

/* html,body{
  scrollbar-color: #71afdb #1a1b1c;
    scrollbar-width: thin;
} */

.row_new_order_align .btn_inline_column .btn
{
min-width: 80px !important;
font-size: 12px !important;
}

@media only screen and (max-width:575px)
{
  .row_new_order_align .btn_inline_column .btn
  {
    font-size: 10px !important;
    min-width: unset !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .row_new_order_align .col-6
  {
padding-left: 2px !important;
padding-right: 2px !important;
  }
}


@media only screen and (min-width:576px) and (max-width:991px)
{
  .row_new_order_align .btn_inline_column .btn
  {
    font-size: 10px !important;
    min-width: 50px !important;
   
  }
}



.person img{
  aspect-ratio: 1/1;
}

/* / TREE CSS / */
.referrallist .headsec .leftsec{
  display: flex;
  gap: 10px;
}
.referrallist .treemap .avatarimg{
  width: 90px;
  border-radius: 50%;
  background: #63b2ff;
  padding: 10px;
  border: 5px solid;
}
.referrallist .treemap .membername{
  text-align: center;
    background: #63b1fe;
    padding: 5px;
    font-size: 14px;
}

.referrallist .treemap h1 {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 50px;
}
.referrallist .treemap .mgt-container {
  flex-grow: 1;
  overflow: auto;
  justify-content: center;
}
.referrallist .treemap .basic-style {
  background-color: #efe6e2;
}
.referrallist .treemap .mgt-item-parent p {
  font-weight: 400;
  color: #ffffff;
  background: #63b2ff;
  padding: 5px;
  font-size: 14px;
  margin-top: 10px;
}

.referrallist .treemap .management-tree {
  
  padding: 40px;
}
.referrallist .treemap .management-tree .person {
  text-align: center;
}
.referrallist .treemap .management-tree .person img {
  max-width: 80px;
  min-width: 80px;
  min-height: 80px;
  max-height: 80px;
  border: 5px solid #ffffff;
  border-radius: 50%;
  overflow: hidden;
  background-color: #ffffff;
  object-fit: cover;
  /* object-position: top; */
}
.referrallist .treemap .cur_pointer_tree .person
{
  position: relative;
}
.referrallist .treemap .cur_pointer_tree .person:after
{
  position: absolute;
    content: "";
    width: 1px;
    height: 10px;
    bottom: 0;
    top: 70px;
    left: 50%;
    background-color: #424242;
    transform: translateY(100%);
}
.referrallist .treemap .management-tree .person p.name {
  background-color: #63b2ff;
  padding: 5px;
  font-size: 12px;
  font-weight: normal;
  color: #ffffff;
  margin: 0;
  position: relative;
  margin-top: 8px;
}

.referrallist .treemap .management-tree .person p.name:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 8px;
  background-color: #424242;
  left: 50%;
  top: 0;
  transform: translateY(-100%);
}

.referrallist .treemap .mgt-wrapper {
  display: flex;
  margin-bottom: 30px;
}
.referrallist .treemap .mgt-wrapper .mgt-item {
  display: flex;
  flex-direction: column;
  margin: auto;
}
.referrallist .treemap .mgt-wrapper .mgt-item .mgt-item-parent {
  margin-bottom: 50px;
  position: relative;
  display: flex;
  justify-content: center;
}

.referrallist .treemap .mgt-wrapper .mgt-item .mgt-item-parent:after {
  position: absolute;
  content: "";
  width: 1px;
  height: 27px;
  bottom: 0;
  left: 50%;
  background-color: #424242;
  transform: translateY(100%);
}
.referrallist .treemap .mgt-wrapper .mgt-item .mgt-item-parent.parent_first:after
{
content: none !important;
}
.referrallist .treemap .mgt-wrapper .mgt-item .mgt-item-mainparent {
  margin-top: 0px;
  margin-bottom: 50px;
  position: relative;
  display: flex;
  justify-content: center;
}
.referrallist .treemap .mgt-wrapper .mgt-item .mgt-item-mainparent:after {
  position: absolute;
  content: "";
  width: 1px;
  height: 27px;
  bottom: 0;
  left: 50%;
  background-color: #424242;
  transform: translateY(100%);
}
.referrallist .treemap .mgt-wrapper .mgt-item .mgt-item-children {
  display: flex;
  justify-content: center;
}
.referrallist .treemap .mgt-wrapper .mgt-item .mgt-item-children .mgt-item-child {
  padding: 0 15px;
  position: relative;
}
.referrallist .treemap .mgt-wrapper .mgt-item .mgt-item-children .mgt-item-child:before,
.referrallist .treemap .mgt-wrapper .mgt-item .mgt-item-children .mgt-item-child:after {
  content: "";
  position: absolute;
  background-color: #424242;
  left: 0;
}
.referrallist .treemap .mgt-wrapper .mgt-item .mgt-item-children .mgt-item-child:before {
  left: 50%;
  top: 0;
  transform: translateY(-100%);
  width: 1px;
  height: 23px;
}
.referrallist .treemap .mgt-wrapper .mgt-item .mgt-item-children .mgt-item-child:after {
  top: -23px;
  transform: translateY(-100%);
  height: 1px;
  width: 100%;
}
.referrallist .treemap .mgt-wrapper .mgt-item .mgt-item-children .mgt-item-child:first-child:after {
  left: 50%;
  width: 50%;
}
.referrallist .treemap .mgt-wrapper .mgt-item .mgt-item-children .mgt-item-child:last-child:after {
 content: none !important;
  width: calc(50% + 1px);
}
.referrallist .treemap .mgt-wrapper .mgt-item .mgt-item-children .mgt-item-child.lastchildadd:last-child:after {
   width: calc(50% + 1px);
   content: "" !important;
 }
 .referrallist .treemap .mgt-wrapper .mgt-item .mgt-item-children .mgt-item-child.grand_child::before
 {
  content: none !important;

 }

 .referrallist .treemap .mgt-wrapper .mgt-item .mgt-item-mainparent.main_parent_no_after:after
 {
  content: none !important;
 }

 .referrallist .treemap .mgt-wrapper .mgt-item .mgt-item-children .main_parent_no_after .mgt-item-child:after
 {
content: "" !important;
 }

 .referrallist .treemap .mgt-wrapper .mgt-item .mgt-item-children .main_parent_no_after .mgt-item-child.last_child_no_after:after {
  content: none !important;
}
.cur_pointer_tree
{
  cursor: pointer;
}
 /* .referrallist .treemap .mgt-wrapper .mgt-item .mgt-item-children .main_parent_no_after .mgt-item-child:first-child:after
 {
content: none !important;
 } */
.referrallist .treemap .button {
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 14px;
  font-weight: 100;
  font-family: "Segoe UI";
  letter-spacing: 1px;
}
.referrallist .treemap .button:before {
  border-radius: 100px;
  border: 2px solid #468cdc;
  box-shadow: 0 0 15px rgba(0,255,204,0.15), 0 0 15px rgba(0,255,204,0.15) inset;
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  height: 80px;
  width: 80px;
  margin-left: -40px;
  margin-top: -40px;
  opacity: 1;
  transform: scale(1);
  transition: all 300ms;
}
.referrallist .treemap .button:hover:before {
  transform: scale(1.05);
}
.referrallist .treemap .button.circle {
  background: #63b2ff;
  border: none;
  border-radius: 900px;
  color: #fff !important;
  cursor: pointer;
  display: flex;
  width: 70px;
  height: 70px;
  line-height: 100px;
  position: relative;
  margin: auto;
  margin-top: 5px;
  transition: 0.5s;
  align-items: center;
  justify-content: center;
}
.referrallist .treemap .button.circle:hover {
  background: #3e70aa;
  padding: -2px;
}
.referrallist .treemap .management-tree .lastchildadd .person p.name {

  width: 80px;
margin-top: 15px;
}
.referrallist .treemap .management-tree .lastchildadd .person p.name:before {
  height: 8px;
}
@media only screen and (max-width:991px){
  .referrallist .treemap .management-tree {
    padding: 40px;
    padding-left: 0px;
    padding-right: 0px;
}
}
.cur_pointer_tree
{
  cursor: pointer;
}

.referrallist .treemap .button:after {
 
  content: "Load More";
  display: block;
  font-size: 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  height: 80px;
  width: 80px;
  margin-left: -40px;
  margin-top: -47px;
  overflow: hidden;
  opacity: 1;
  transform: scale(1);
  transition: all 300ms;
}
.referrallist .treemap .lastchildadd .cur_pointer_tree .person:after
{
  content: none !important;
}

.empty_child
{
  max-width: 80px;
    min-width: 80px;
    min-height: 80px;
    max-height: 80px;
    border: 5px solid #ffffff;
    border-radius: 50%;
    background-color: #6ab2fc;
    margin-top: -23px;
    display: flex;
    align-items: center;
    justify-content: center;

}
.empty_child p{
  color:#fff;
}
/* / END TREE CSS / */


.br-bottom{
  border-bottom: 1px solid #a5a5a5 !important;
}
.react-datepicker-popper.some-custom-class
{
  z-index: 9999999 !important;
}
.graytab + .tab-content .some-custom-class div
{
padding: unset !important;
}

.calendar_accordion .some-custom-class button
{
width: 100% !important;
padding: unset !important;
}

/* .some-custom-class .react-datepicker__navigation-icon--previous
{
right: 100px !important;
} */


/* .some-custom-class .react-datepicker__navigation-icon--next
{
left: 100px !important;
} */
.graytab + .tab-content .some-custom-class div.react-datepicker__header 
{
padding: 10px 0px !important;
}
.react-datepicker__input-container input
{
  background-color: rgb(83, 83, 83);
    border: 1px solid #292929 !important;
    outline: 0px !important;
    border-radius: 5px !important;
    min-height: 25px !important;
    width: 100% !important;
}
.react-datepicker-popper[data-placement^=top]
{
  transform: translate(0px, -197px) !important;
}
.react-datepicker__tab-loop,.react-datepicker__tab-loop div,.react-datepicker-wrapper
{
  padding: 0px !important;
}
.react-datepicker__input-container
{
  padding-right: 0px !important;

}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after
{
  left: 0px !important;
  right: 20px !important;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before

{
  content: none !important;
}

@media only screen and (min-width:500px) and (max-width:767px)
{
  .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after
  {
    left: 20px !important;
  right: 0px !important;
  }
}

.modal_new_caldr .react-datepicker__input-container input,
.modal_new_caldr_1 .react-datepicker__input-container input,
.modal_new_caldr_2 .react-datepicker__input-container input,
.modal_new_caldr_3 .react-datepicker__input-container input


{
  background-color: rgb(41 41 41) !important;
  /* margin-bottom: 10px !important; */
}
.modal_new_caldr .react-datepicker__tab-loop div .react-datepicker__header,
.modal_new_caldr_1 .react-datepicker__tab-loop div .react-datepicker__header,
.modal_new_caldr_2 .react-datepicker__tab-loop div .react-datepicker__header,
.modal_new_caldr_3 .react-datepicker__tab-loop div .react-datepicker__header 
{
  padding: 10px 0px !important;
}

.modal_new_caldr .react-datepicker-popper[data-placement^=top]
{
  transform: translate3d(-16px, -338.5px, 0px) !important;
}

.modal_new_caldr_2 .react-datepicker-popper[data-placement^=top]
{
  transform: translate(-220px, -401px) !important;
}

.modal_new_caldr_3 .react-datepicker-popper[data-placement^=top]
{
  transform: translate(-165px, -268px) !important;
}
@media only screen and (min-width:576px) and (max-width:991px)
{
  .modal_new_caldr .react-datepicker-popper[data-placement^=top] {
    transform: translate3d(-201px, -260.5px, 0px) !important;
}
  .react-datepicker-popper[data-placement^=top] {
    transform: translate(-25px, -190px) !important;
}
.modal_new_caldr_2 .react-datepicker-popper[data-placement^=top] {
  transform: translate(-165px, -268px) !important;
}
  .react-datepicker-popper[data-placement^=top].some-custom-class-2 {
    transform: translate(-25px, -246px) !important;
}
.react-datepicker-popper[data-placement^=top].some-custom-class-3 {
  transform: translate(-25px, -246px) !important;
}
}
.react-datepicker-popper[data-placement^=top].some-custom-class-2 {
  transform: translate(-25px, -246px) !important;
}
.react-datepicker-popper[data-placement^=top].some-custom-class-3 {
  transform: translate(-25px, -246px) !important;
}

@media only screen and (min-width:992px)
{
  .modal_new_caldr .react-datepicker-popper[data-placement^=top] {
    transform: translate3d(-392px, -260.5px, 0px) !important;
}
.modal_new_caldr_2 .react-datepicker-popper[data-placement^=top] {
  transform: translate(-421px, -260px) !important;
}
.modal_new_caldr_3 .react-datepicker-popper[data-placement^=top] {
  transform: translate(-421px, -260px) !important;
}
}

@media only screen and (max-width:575px)
{
  .modal_new_caldr .react-datepicker__input-container input,
  .modal_new_caldr_2 .react-datepicker__input-container input,
  .modal_new_caldr_3 .react-datepicker__input-container input

  {
    margin-bottom: 10px !important;
  }
 
  .modal_new_caldr_2 .react-datepicker-popper[data-placement^=top] {
    transform: translate(-25px, -444px) !important;
}
.modal_new_caldr_3 .react-datepicker-popper[data-placement^=top] {
  transform: translate(-25px, -444px) !important;
}

}
/* .react-datepicker__tab-loop
{
display: none !important;
} */
.light_theme .statlist p
{
font-size: 12px !important;
}
h6.text_normal
{
  text-transform: capitalize !important;
}
/* fieldset[disabled][disabled] .form-control, .form-control.disabled, .form-control:disabled, .form-control[disabled]
{
  background-color:#767676 !important;
} */
.some-custom-class .react-datepicker
{
  display: flex;
}
.react-datepicker__month
{
  margin: 0px !important;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name
{
  margin-right: 0px !important;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after
{
  content: none !important;
}
.drop.w-full .jc-between
{
height: 25px !important;
}

@media only screen and (max-width:575px)
{
  .modal_new_caldr .react-datepicker-popper[data-placement^=top] {
    transform: translate3d(-2px, -295.5px, 0px) !important;
}
}

@media only screen and (min-width:1200px) and (max-width:1400px)
{
  .sectn_date_sm .react-datepicker__day-name,
  .sectn_date_sm .react-datepicker__day,
  .sectn_date_sm .react-datepicker__time-name
  {
    width: 1.35rem !important;
  }
  .sectn_date_sm .react-datepicker__time-container,
  .sectn_date_sm .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box

  {
    width: 65px !important;
  }
}

@media only screen and (min-width:768px) and (max-width:991px)
{
  .sectn_date_sm .react-datepicker-popper[data-placement^=top]
  {
    transform: translate(0px, -190px) !important;
  }

  .sectn_date_sm .react-datepicker__time-container,
  .sectn_date_sm .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box
  {
    width: 65px !important;

  }
  .sectn_date_sm .react-datepicker__day-name,
  .sectn_date_sm .react-datepicker__day,
  .sectn_date_sm .react-datepicker__time-name
  {
    width: 1.3rem !important;
  }
}

.demo_live_dd_menu a.dropdown-item:hover
{
  background-color: #121212 !important;
  color:#fff !important;
}
.btn_nothing_bg
{
  background-color: transparent !important;
  background: transparent !important;
  border:none !important;
  color:#fff !important;
  margin-top: 0px !important;
  padding-top: 5px !important;
  font-size: 12px !important;
}
.font_12_user
{
  font-size: 12px !important;
}
.btn_nothing_bg:hover .font_12_user
{
color:#63b2ff !important;
}
.light_theme .coinlists ul p
{
  font-size: 12px !important;
}
.lastnumber{
  font-size: 10px !important;
}
.sellnumber {
  color: white !important;
  font-size: 10px !important;
}
.buynumber{
  color: white !important;
  font-size: 10px !important;
}
.marketnumber{
  font-size: 18px !important;
}
/* .MuiMenu-paper{
  top: 300px !important;
} */
/* .num{
  color: black;
  font-size: 13px;
  font-family: sans-serif;
}
.numStyle{
  font-weight: bold;
  opacity: 0.5;
  color: red;
} */
/* .light_theme .coinlists ul p:first-letter {
  color: blue;
} */
.fleex_bet_stalist
{
  justify-content: space-between;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  border-radius: 4px;
  margin-right: 2px;
  filter: invert(1);
}
.themeselect {
  border-color: #787272 !important;
  border: 1px solid black;
}

.tooltipss {
  position: relative !important;
  display: inline-block !important;
  /* border-bottom: 1px dotted black !important; */
  width: 100%;
}

.tooltipss .tooltiptextss {
  visibility: hidden;
  width: 180px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px 10px;
  margin-top: 2px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}
.tooltipss .tooltiptextss1 {
  width: 200px !important;
  top: 100% !important;
  font-size: 12px !important;
}
.tooltipss .tooltiptextss11{
  width: max-content !important;
    bottom: 35% !important;
    padding: 5px 10px !important;
}
.tooltipss:hover .tooltiptextss {
  visibility: visible;
}

.__react_component_tooltip{
 padding-top: 8px !important;
 padding-bottom: 10px !important;
 padding-left: 8px !important;
 padding-right: 8px !important;
 /* width: 300px !important; */
 white-space: inherit !important;
 word-wrap: break-word !important;

}

@media only screen and (max-width:575px)
{
  .__react_component_tooltip
  {
    font-size: 10px !important;
    right: 0px !important;
    left:unset !important;
    padding-left: 20px !important;
    /* width: 300px !important;  */
    white-space: pre-wrap !important;
  }
  .statlist div.__react_component_tooltip
  {
    padding-left: 10px !important;

  }
}



@media only screen and (min-width:576px) and (max-width:767px)
{
  .__react_component_tooltip
  {
    font-size: 12px !important;
    /* left:30px !important; */
    /* right: 30px !important; */
    max-width: 400px !important;
    /* right: 50% !important; */
    /* left:50% !important; */
    padding-left: 20px !important;
    /* width: 300px !important;  */
    white-space: pre-wrap !important;
  }
  .statlist div.__react_component_tooltip
  {
    padding-left: 10px !important;

  }
}


@media only screen and (min-width:768px) and (max-width:991px)
{
  .__react_component_tooltip
  {
    font-size: 12px !important;
    /* left:30px !important; */
    /* right: 30px !important; */
    max-width: 600px !important;
    /* right: 50% !important; */
    /* left:50% !important; */
    padding-left: 20px !important;
    /* width: 300px !important;  */
    white-space: pre-wrap !important; 
    /* right: 30px !important; */
    left:unset !important;
  }
  .statlist div.__react_component_tooltip
  {
    padding-left: 10px !important;

  }
}
@media only screen and (min-width:992px) and (max-width:1199px)
{
  .__react_component_tooltip
  {
    font-size: 12px !important;
    /* left:30px !important; */
    /* right: 30px !important; */
    max-width: 800px !important;
    /* right: 50% !important; */
    /* left:50% !important; */
    padding-left: 20px !important;
    /* width: 300px !important;  */
    white-space: pre-wrap !important; 
    /* right: 30px !important; */
    left:unset !important;
  }
  .statlist div.__react_component_tooltip
  {
    padding-left: 10px !important;

  }
}
.__react_component_tooltip.place-top{
  padding-left: 8px !important;
  padding-right: 8px !important;
  /* left:unset !important; */
  /* width: 50% !important;
  white-space: inherit !important;
  word-wrap: break-word !important; */
  }
/* .tooltip{
 transform: translate(0px,0px) !important;
} */

.stoptake{
  min-height: 100%;
line-height: 1.5;
}
.informationmodal  .input_grp_number.input_typ_gere .input-group-append{
  background-color: #292929 !important;
}
.input_grp_number.input_typ_gere .input-group-append.font_sm_ap:last-child{
  border-radius: 0 5px 5px 0;
  padding-right: 7px;
}
.informationmodal  .inputboxbg {
  background: #292929 !important;
}
.informationmodal .font_label_sm,.informationmodal .font_label_sm .d-flex{
margin-bottom: 5px;
}
.red{
 background-color:  #f13a0b !important;
 border-color: #f13a0b !important;
}
button.btn.green-btn.w-full.red{
  background-color:  #f13a0b !important;
  border-color: #f13a0b !important;
}
button.btn.green-btn.w-full.red:hover{
  background-color:  transparent !important;
  border-color: #f13a0b !important;
  color: #fff;
}

button.loading_btn:disabled
{
  opacity: 1 !important;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before
{
  border-color: #000 !important;
}

.some-custom-class .react-datepicker__navigation-icon--previous
{
top: -8px !important;
}


.some-custom-class .react-datepicker__navigation-icon--next
{
top: -8px !important;
}
.loader{
  /* background-image: url(../images/loader.png) !important; */
  background-size: contain;

  /* background-size: 100% 100%; */
  border-radius: 0 !important;
  transform: rotate(0);
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #030303;
}

.loader img{
  margin: auto;
  width: auto;
  max-width: 100%;
}
.table_error_pair{
  color: #fff !important;
}
.load_more_done_ord{
  color: #fff !important;
  font-weight: bolder !important;
}
.load_more_done_ord:hover{
 background: #2769ba;
}
.custdacc.inneracc .dropdown-menu.show .dropdown-item:first-child {
  background: #2769ba;
}
.splitbtn button:first-child{
  border-radius: 5px  0 0 5px !important;
}
.splitbtn button.dropdown-toggle{
  padding-left: 10px !important;
  padding-right: 10px !important;
  border-radius: 0 5px 5px 0 !important;
}
.splitbtn .dropdown-menu.show {
  background: #3b3b3b !important;
  min-width: 227px;
}
.splitbtn .dropdown-menu a.dropdown-item{
  color: #fff !important;
  padding-left: 10px;
  padding-right: 10px;
}
.splitbtn .dropdown-menu a.dropdown-item:hover{
background-color: #4caf50 !important
}

.apexcharts-xaxis, .apexcharts-yaxis
{
  display: block !important;
}
.tab_yr{
  position: relative;
}
.tab_yr.height_tabyr_align{
  min-height: 285px;
}
.performance_nodata_txt{
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.roiequidity_nodata_align{
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tab_yr .chartnav{
  position: absolute;
  z-index: 9;
  top: -15%;
  right: 0px;
}
.tab_yr .chartnav .chartnav_in{
  position: relative;
}
.tab_yr .chartnav .chartnav_in .inchart{
  right: 50px;
}
.tab_yr .chartnav .chartnav_in .inchart.next_yr{
  right: 100px;
}
@media only screen and (max-width:575px)
{
  .tab_yr .chartnav .chartnav_in .inchart{
    right: 20px !important;
    top: 40px !important;
  }
  .tab_yr .chartnav .chartnav_in .inchart.next_yr{
    right: 70px !important;
  }
}
@media only screen and (min-width: 576px) and (max-width: 991px)

{
  .tab_yr .chartnav .chartnav_in .inchart{
    right: 50px !important;
    top: 40px !important;
  }
  .tab_yr .chartnav .chartnav_in .inchart.next_yr{
    right: 100px !important;
  }

}
.sec_5 .sec_5_row{
  align-items: center;
}
.contact_page_title .login_title_8{
  font-size: 40px;
  font-weight: 700;
  color: #63b2ff !important;
}
.contact_page_title .submit_btn button:hover{
  background: transparent!important;
  color: #fff !important;
}
.faq_new.settingsContent .p2p_card{
  margin-top: 100px !important;
}
.faq_new.settingsContent .dash_title.login_title_8{
  text-align: center;
  color: #63b2ff !important;
  font-size: 35px;
}

.calendar_accordion .some-custom-class button
{
  width: 15% !important;
  margin-top: -4px !important;

}

.react-datepicker-popper.some-custom-class .react-datepicker__day-name,.react-datepicker-popper.some-custom-class .react-datepicker__day,.react-datepicker-popper.some-custom-class .react-datepicker__time-name
{
  margin: 0.1rem !important;
}


@media only screen and (min-width: 768px) and (max-width: 991px)
{
  .sectn_date_sm .react-datepicker-popper.some-custom-class .react-datepicker__day-name,
  .sectn_date_sm .react-datepicker-popper.some-custom-class .react-datepicker__day,
  .sectn_date_sm .react-datepicker-popper.some-custom-class .react-datepicker__time-name {
    width: 1.2rem !important;
}
}

.acoutn_long_inpt
{
  flex-direction: column;
  align-items: flex-start !important;
  margin-top:10px;
  margin-bottom: 10px;
}
.acoutn_long_inpt select
{
  width: 100% !important;
  margin-top: 10px;
  padding: 8px !important;
  height: 35px;
}
/* .acoutn_long_inpt select option
   {
    max-width: 100%;
    max-width: 100px !important;

    overflow: hidden;
    word-wrap: break-all !important;
    white-space: break-spaces !important;
} */


.acoutn_long_inpt select {
  width: 100% !important;
  overflow: hidden !important;
  white-space: pre;
  text-overflow: ellipsis;
  -webkit-appearance: none;
}

.acoutn_long_inpt option {
  border: solid 1px #DDDDDD;
}
.back_bt_det:hover,.btn-outline-secondary.back_bt_det:hover,
.btn_banc_old.btn-outline-secondary:hover
{
  background-color: #979797 !important;
  background: #979797 !important;

  color:#fff !important;
}
.deal_show_copir
{
  background-color: #525252;
}
.deal_show_copir
{
  flex-direction: column;
  align-items: flex-start !important;
  justify-content: flex-start !important;
}
.sho_accu_div_full
{
  display: block !important;
}
.sho_accu_div_full .themeselect 

{
  width: 100% !important;
    margin: 10px 0px;
}