.flex-1>* {
    flex: 1;
}

.f-12 {
    font-size: 12px;
}

.f-10 {
    font-size: 10px;
}

.inputboxbg {
    background: #535353;
    border-radius: 5px;
    padding: 4px !important;
}

.themeselect1 {
    background-color: transparent !important;
    // border-color: #292929 !important;
    height: 35px !important;
    font-size: 24px !important;
}

select.selectcount.themeselect.themeselect1.my-2.w-full option {
    background: #121212 !important;
}

.mw-610 {
    max-width: 610px;
    min-width: 480px;
}

hr.themehr {
    margin: 4px 0;
    background-color: #3c3c3c !important;
}

.br-0 {
    border-radius: 0;
}

.fw-600 {
    font-weight: 600;
}

.flex1 {
    flex: 1;
}

.flex-05 {
    flex: 0.5;
}

.bottom-nav a {
    color: #fff !important;
}

.curser,
.cursor {
    cursor: pointer;
}

.provider-overlay {
    background: #ff000000;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9;
}

.topmenus .dropdown-menu.show a:hover {
    background: transparent !important;
    color: #63b2ff !important;
}

.usd {
    background: #292929;
    font-size: 11px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 4px;
}

button.browse {
    border-radius: 4px;
    border: 0;
    margin: 4px 0;
}

.browseinput {
    display: flex;
    background: #292929;
    position: relative;
    flex: 0.5;
    justify-content: space-between;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 4px;
    align-items: center;
}

.imgfile {
    position: relative;
}

.imgfile input {
    position: absolute;
    width: 34px;
    bottom: 7px;
    right: 0;
    opacity: 0;
    z-index: 9;
    cursor: pointer;
}

.btn-light.gray {
    background-color: #999999 !important;
    border-color: #999999 !important;
}

.uploadmodal .modal-content {
    background: #232323 !important;
}

.uploadmodal .themeselect option {
    padding: 10px;
}

.uploadmodal .themeselect:focus,
.uploadmodal .themeinput:focus,
.uploadmodal .browseinput {
    background: transparent;
    border: 1px solid gray;
    ;
}

.uploadmodal .themeselect,
.uploadmodal .themeinput,
.uploadmodal .browseinput {
    background: transparent;
    border: 1px solid gray;
    padding: 8px;
    height: auto;
}

.themeinput {
    border: none;
}

.browseinput input {
    position: absolute;
    width: 100%;
    opacity: 0;
}

.upload svg {
    height: 19px;
    fill: #fff;
    background: #6b6b6b;
    padding: 4px;
    border-radius: 4px;
    margin-left: 4px;
}

.usd input {
    background: #292929;
    border: none;
    border-radius: 4px;
    font-size: 12px !important;
    min-width: 190px;
}

.leftmenu .themeinput {
    min-width: 150px;
}

.lightgray {
    color: lightgrey !important;
}

.themebtn.w-72+div a {
    min-width: 72px !important;
}

button.movebtn {
    background: transparent;
    border: none;
    color: lightgray;
}

button.pages.active,
button.pages.active:hover {
    background: #009345;
}

button.pages {
    background: transparent;
    border: 0;
    margin: 3px;
    border-radius: 3px;
    color: #fff;
    font-size: 12px;
}

button.pages:hover {
    background: gray;
}

// .apexcharts-tooltip,.apexcharts-xaxistooltip {
//     display: none !important;
// }
button.strategydrop {
    background-color: #000;
    color: #FFF;
}

.font-11 * {
    font-size: 11px;
}

.filter_button {
    position: absolute
}

.apexcharts-data-labels {
    display: none;
}

.apexcharts-xaxis,
.apexcharts-yaxis,
.apexcharts-toolbar {
    display: none !important;
}

.apexcharts-ycrosshairs {
    stroke-width: 0.5 !important;
}

.copytrade li {
    // border-bottom: 1px solid #3c3c3c !important;
}

.filtermodal .modal-footer {
    border-top: 1px solid #464646;
}

.tradechart {
    background: #000;
}

.bg-black {
    background-color: #000 !important;
}

.topcheck .form-check label {
    padding-left: 0 !important;
    font-size: 12px;
}

.provider {
    position: absolute;
    left: 25px;
    top: 25px;
    z-index: 99
}

.elipsis {
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
}

div#apexchartsbasic-bar {
    margin: auto;

    svg g {
        transform: matrix(1.1, 0, 0, 0, 0)
    }
}

.tradechart {
    max-height: 100px;
    min-height: 142px !important;
}

#chart {
    max-width: 650px;
    margin: 35px auto;
}

.pagetop {
    margin-top: 90px;
}

.copybtn {
    color: var(--themeclr);
    background: transparent;
    border: none;
    font-size: 18px;
}

.lightbox {
    background-color: #525251;
}

.leftmenu a,
.rightmenu a {
    color: #fff !important;
}

.rightmenu a {
    padding: 2px !important;
}

.copyscroll {
    height: calc(100vh - 187px);
    overflow: auto;
    scrollbar-color: #63b2ff #c2d2e4;
    scrollbar-width: thin;
}

.favscroll {
    height: calc(100vh - 117px);
    overflow: auto;
    scrollbar-color: #63b2ff #c2d2e4;
    scrollbar-width: thin;
}

.themebtn {
    overflow: hidden;

    &.w-60+div {
        max-width: 60px !important;
        min-width: 60px !important;
        overflow: hidden;

        a {
            width: 60px !important;
            padding: 0 !important;
            color: #fff;
            text-align: center;
        }
    }

    &.w-72 {
        max-width: 72px !important;
        min-width: 72px !important;

        &+div {
            max-width: 72px !important;
            min-width: 72px !important;

            a {
                width: 72px !important;
                padding: 0 !important;
                color: #fff;
                text-align: center;
            }
        }
    }
}


@mixin custscroll {
    &::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #101010;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #63b2ff;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

@mixin marketdrop {
    .marketdrop {

        .dropdown {
            margin-bottom: 5px;

            button {
                background-color: #525252 !important;
                border-color: #525252 !important;
                text-align: left;
                width: 100%;

                &:focus {
                    box-shadow: none !important;
                }
            }

            .dropdown-menu {
                background-color: #1a1a1a;
                position: relative !important;
                transform: translate(0) !important;

                a {
                    color: #fff;

                    &:hover {
                        background-color: #363636;
                    }
                }

            }
        }
    }
}

.themetab {
    .nav-tabs {
        display: flex;
        border: 0;
        gap: 1px;
        flex-wrap: nowrap;
        overflow-x: auto;
        scrollbar-color: #63b2ff #c2d2e4;
        scrollbar-width: thin;
        padding-bottom: 14px;

        .nav-item {
            white-space: nowrap;
            min-width: 140px;
            flex: 1;
            background: #3b3b3b;
            border-bottom: 1px solid #2769ba !important;
            border-top: 0;
            border-right: 0;
            border-left: 0;
            color: #fff !important;
            padding: 5px;
            width: 100%;
            text-transform: capitalize;
            text-align: center;
            font-size: 12px;

            &.active {
                background: #4a4a4a;
                border-bottom: 0px solid #2769ba !important;
            }

        }
    }
}

.modal-content {
    .modal-body {
        padding: 1rem;
    }

    .modal-header {
        background-color: #2769ba;
        padding: 6px 11px;
        border: 0;

        .modal-title {
            font-size: 16px;
        }
    }
}

.sidbaracc {
    &>.card {
        background: #292929;

        &>.card-header {
            background-color: #3b3b3b;
            margin-top: 0;
            padding: 0;
            margin: 0;
            margin-left: 2px;

            button {
                text-align: left;
                padding: 0;
                background-color: #2769ba !important;
                border-color: #3b3b3b !important;
                color: #fff;
                width: 100%;
                height: 100%;
                display: block;
                margin: 0;

                img {
                    background: #2769ba;
                    padding-right: 5px;
                }

                &.collapsed {
                    background-color: #3b3b3b !important;
                    color: #fff
                }

                &:focus {
                    box-shadow: none;
                }

                p {
                    color: #fff;
                }

                &:after {
                    display: none;
                }
            }
        }

        .card-body {
            .nav-tabs {
                display: flex;
                border: 0;
                padding: 0;
                padding-left: 8px;

                .nav-item {
                    flex: 1;
                    background: #3b3b3b;
                    border-bottom: 1px solid #2769ba !important;
                    border-top: 0;
                    border-right: 0;
                    border-left: 0;
                    color: #fff !important;
                    padding: 5px;
                    width: 100%;
                    text-align: center;
                    text-transform: capitalize;
                    font-size: 12px;

                    &.active {
                        background: #4a4a4a;
                        border-bottom: 0px solid #2769ba !important;
                    }

                }
            }
        }
    }
}

//   .sidbaracc{
//     &>.accordion-item{
//         background: #292929;
//         &>.accordion-header{
//             background-color: #3b3b3b;
//             button{
//                 padding: 0;
//                 background-color: #2769ba !important;
//                 border-color: #3b3b3b !important;
//                 color: #fff;
//                 img{
//                     background: #2769ba;
//                     padding-right: 5px;
//                 }
//                 &.collapsed{
//                     background-color: #3b3b3b !important;
//                     color:#fff
//                 }
//                 &:focus{
//                     box-shadow: none;
//                 }
//                 p{
//                     color: #fff;
//                 }
//                 &:after{
//                     display: none;
//                 }
//             }
//         }
//         .accordion-body{
//             ul.nav-tabs{
//                 display: flex;
//                 border: 0;
//                 li.nav-item{
//                     flex: 1;
//                     button.active{
//                         background: #4a4a4a;
//                         border-bottom: 0px solid #2769ba !important;
//                     }
//                     button{
//                         background: #3b3b3b;
//                         border-bottom: 1px solid #2769ba !important;
//                         border-top: 0;
//                         border-right: 0;
//                         border-left: 0;
//                         color: #fff;
//                         padding: 5px;
//                         width: 100%;
//                     }
//                 }
//             }
//         }
//     }
// }

// .inneracc{
//     &>.accordion-item{
//         background: transparent;
//         border: 0;
//         margin-bottom: 5px;
//         &>.accordion-header{
//             background-color: #3b3b3b;

//             button{
//                 padding: 5px;
//                 background-color: #525252 !important;
//                 border-color: #3b3b3b !important;
//                 color: #fff;
//                 display: flex;
//                 justify-content: space-between;
//                 button{
//                     padding: 0;
//                     border: 0;
//                 }
//                 img{
//                     background: #2769ba;
//                     padding-right: 5px;
//                 }

//                 &:focus{
//                     box-shadow: none;
//                 }
//                 p{
//                     color: #fff;
//                     font-size: 12px;
//                 }
//                 &:after{
//                     display: none;
//                 }
//             }
//         }
//         .accordion-collapse {
//         .accordion-body{
//             ul{
//                 display: flex;
//                 border: 0;
//                 li{
//                     flex: 1;
//                     button.active{
//                         background: #4a4a4a;
//                         border-bottom: 0px solid #2769ba !important;
//                     }
//                     button{
//                         background: #3b3b3b;
//                         border-bottom: 1px solid #2769ba !important;
//                         border-top: 0;
//                         border-right: 0;
//                         border-left: 0;
//                         color: #fff;
//                         padding: 0;
//                         width: 100%;
//                     }
//                 }
//             } 
//         }
//         &.collapsed{
//             background-color: #3b3b3b !important;
//             color:#fff
//         }
//         &.collapse.show{
//             background-color: #292929 !important;
//             border-color: #292929 !important;
//         }
//     }
//     }
// }
.inneracc {
    .acc-item {
        padding: 0 5px 0 5px;
        background-color: #525252 !important;
        border-color: #3b3b3b !important;
        color: #fff;
        display: flex;
        justify-content: space-between;
        min-height: 25px;
        border-radius: 3px;

        button {
            color: #fff;
            background-color: transparent;
            border: 0;
            padding: 0;
            text-transform: capitalize;

            span {
                font-size: 12px !important;
            }
        }

        .btn-outline-secondary {
            color: #b4b8bc !important;
            border: 1px solid #b4b8bc !important;
            padding: 0px 4px !important;
            font-size: 10px;
        }

        .dropdown.topdrop {
            .dropdown-menu {
                background-color: #1a1a1a;

                .seconddrop {
                    .dropdown-menu {
                        color: #fff;
                        position: relative !important;
                        transform: translate(0px, -1px) !important;
                        font-size: 12px;

                        a {
                            color: #fff;
                            background-color: #1a1a1a;

                            &:hover {
                                background-color: #363636;
                            }

                            .dropdown-menu {
                                position: relative !important;
                                transform: translate(0px, -1px) !important;
                            }

                        }
                    }

                    button {
                        background-color: #525252;
                        padding: 6px 10px;
                        width: 100%;
                        display: flex;

                        &:after {
                            display: none;
                        }

                        &:focus {
                            background-color: transparent;
                            box-shadow: none;
                        }

                        &:hover {
                            background-color: #5e5e5e;
                        }
                    }
                }

                a {
                    padding: 5px;
                    font-size: 12px;

                    &:hover {
                        background-color: transparent;
                    }
                }
            }

            button {
                display: flex;
                background: transparent;

                &:after {
                    display: none;
                }

                &:focus {
                    background-color: transparent;
                    box-shadow: none;
                }
            }

        }
    }

}

.input-group.dark {
    input {
        background-color: #292929;
        border: 0;
        height: 20px;
        color: #fff;

        &:focus {
            box-shadow: none;
        }
    }

    .input-group-text {
        background-color: #292929;
        color: #fff;
        border: 0;
        padding: 0;
        font-size: 11px;
        padding-right: 7px;
    }
}

.coinlists {
    ul {
        li {
            .shrinked {
                border-bottom: 1px solid #4e4e4e;
                padding: 6px;
                align-items: center;
            }

            .expanded {
                border-bottom: 1px solid #4e4e4e;
                padding: 4px;
            }

            &:hover {
                background: #363636;
            }
        }

    }
}

.expanded {
    background-color: #3b3b3b;
    border-radius: 3px;

    p {
        padding-left: 5px;
    }
}

.seconddrop {
    .dropdown-menu {
        color: #fff;
        position: relative !important;
        transform: translate(0px, -1px) !important;
        font-size: 12px;

        a {
            color: #fff;
            background-color: #1a1a1a;

            &:hover {
                background-color: #363636;
            }

            .dropdown-menu {
                position: relative !important;
                transform: translate(0px, -1px) !important;
            }

        }
    }

    button {
        background-color: #525252;
        padding: 6px 10px;
        width: 100%;
        display: flex;

        &:after {
            display: none;
        }

        &:focus {
            background-color: transparent;
            box-shadow: none;
        }

        &:hover {
            background-color: #5e5e5e;
        }
    }
}

button.charticon {

    padding: 0;
    background-color: transparent;
    border: 0;

    &:after {
        display: none;
    }

    img {
        width: 18px;
        filter: invert(0.8);
    }

    &:hover {
        background-color: transparent;

        img {
            filter: invert(1);
        }
    }
}

.dark-select {
    background-color: #000 !important;
    border-color: #000;
    max-width: 86px;
    color: #fff;
    padding: 0 5px;
    font-size: 10px;
    background-image: unset;
    appearance: auto;

    &:focus {
        box-shadow: none !important;
        border-color: #000 !important;
        background-color: #000 !important;
    }

    &:hover {
        background-color: #000;
        border-color: #000;


    }

    &+div {
        background-color: #000;
        max-height: 250px;
        overflow-y: auto;
        scrollbar-color: #63b2ff #c2d2e4;
        scrollbar-width: thin;
        @include custscroll;

        a {
            color: #fff;
            font-size: 12px;

            &:hover {
                background-color: #191919;
                color: #fff;
            }
        }
    }
}

.sellbuybtn {
    position: relative;

    .selectcount {
        position: absolute;
        width: 156px !important;
        min-width: 191px;
        height: 24px;
        left: 0;
        right: 0;
        margin: auto;
        border-color: #3b3b3b;
        border-width: 0 4px 4px;
        border-radius: 0;
        z-index: 9;

        &:focus {
            border-color: #3b3b3b !important;
            border-width: 0 4px 4px !important;
        }

    }

    button {
        padding: 10px 10px 5px;
    }
}

.scrolablediv {
    height: calc(100vh - 395px);
    // height: calc(100vh - 370px);
    overflow-y: auto;
    scrollbar-color: #63b2ff #c2d2e4;
    scrollbar-width: thin;
    @include custscroll
}

.scrolablediv.extendsec {
    height: calc(100vh - 298px);
}

.themeinput {
    background: #292929;
    height: 25px;
    border-radius: 4px;

    input {
        background: #292929;
        height: 18px;
        font-size: 12px !important;

        &::placeholder {
            font-size: 12px;
        }
    }

    .input-group-append {
        height: 18px;

        span {
            font-size: 12px;
            padding: 0;
        }
    }
}

.themeinput,
.themeselect {
    background-color: #292929;
    border-color: #292929;
    height: 25px;
    color: #fff;
    font-size: 12px !important;
    padding: 0px 8px;
    appearance: auto;
    border-radius: 5px;
    background-image: none;
    align-items: center;

    &:focus {
        background-color: #292929;
        border-color: #292929;
        box-shadow: none;
        color: #fff;

    }
}

.bottom-nav {
    background: #525252;
    height: 117px;
    position: absolute;
    width: 100%;
    bottom: 0;
    border-radius: 0px 0px 3px 3px;

    .navbtn {
        background: gray;
        height: 24px;
        padding: 4px;
        line-height: 0;
        position: absolute;
        right: 10px;
        top: -10px;
        color: #fff;
        font-size: 14px;
        z-index: 99;

        &:focus {
            box-shadow: none;
        }
    }

    li {

        a {
            color: #fff;
            text-decoration: none;
            display: block;
            padding: 9px;
        }

        &:hover {
            background-color: #4a4a4a;
        }
    }
}

.bottom-nav.flatnav {
    height: 40px;

    li {
        display: inline-block;

        span {
            display: none;
        }
    }

}

.graytab {

    a {
        background: #3b3b3b;
        border: 0 !important;
        text-transform: capitalize;
        color: #fff !important;
        padding: 5px 10px !important;
        font-size: 12px !important;
        margin-right: 5px;

        &.active {
            background-color: #525252 !important;
        }
    }
}

.graytab+.tab-content {
    background: #525251;
    padding: 5px;
    border-radius: 0 0 3px 3px;

    div {

        padding: 3px;
    }
}

.tradeacc {
    border-bottom: 0;
    width: 140px;
    margin-left: auto;
    display: flex;

    .nav-link {
        background-color: #565656 !important;
        border-color: #565656 !important;
        color: #fff !important;
        border-radius: 5px !important;
        padding: 10px 12px;
        text-transform: capitalize;

        &:hover {
            background-color: gray;
            border-color: gray;
        }
    }

    .nav-link:first-child {
        margin-right: 5px;

        &.active {
            background-color: #009345 !important;
            border-color: #009345 !important;
            color: #fff !important;
        }
    }

    .nav-link:last-child {
        &.active {
            background-color: #0091ea !important;
            border-color: #0091ea !important;
            color: #fff !important;
        }
    }

}


.settingic {
    button {
        padding: 0;
        background: transparent !important;
        border-color: transparent;
        line-height: 0;

        &:after {
            display: none;
        }

        &:focus {
            box-shadow: none;
            border-color: transparent;
        }
    }

    .dropdown-menu {
        background-color: #1a1a1a;

        a:hover {
            background-color: #353535;
        }
    }
}

.themetab {
    @include marketdrop
}

.sellbtn {
    background-color: transparent;
    border: 1px solid #f13a0b !important;
    color: #f13a0b;

    &:hover {
        border: 1px solid #f13a0b !important;
        color: #fff;
        background-color: #f13a0b !important;
    }

    &:focus,
    &.sellbtn_active {
        border: 1px solid #f13a0b !important;
        color: #fff;
        background-color: #f13a0b !important;
    }

    p {
        font-size: 12px;
        color: #fff;
    }
}

.buybtn {
    background-color: #292929;
    color: #fff;
    border: 1px solid #00e27b !important;

    &:hover {
        border: 1px solid #00e27b !important;
        color: #000;
        background-color: #00e27b;
    }

    &:focus {
        border: 1px solid #00e27b !important;
        color: #fff;
        background-color: #00e27b;
    }

    p {
        font-size: 12px;
        color: #fff;
    }

    &:hover {
        p {
            color: #000;
        }
    }

    &.buybtn_active {
        color: #000;
        background-color: #00e27b !important;

        p {
            color: #000;
        }
    }
}

.custdrop {
    position: relative;

    .tab-content {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .wishdrop {
        position: absolute;
        padding: 15px;
        background-color: #1a1a1a;
        width: 100%;
        z-index: 99;

        .marketdrop.scrolablediv {
            height: 200px;
        }
    }
}

.statlist {
    display: flex;
    width: 100%;
    overflow-x: auto;
    scrollbar-color: #63b2ff #c2d2e4;
    scrollbar-width: thin;
    padding-left: 0 !important;

    div {
        display: flex;
        align-items: baseline;
        padding-left: 0 !important;
        padding-right: 10px;

        label {
            margin-right: 5px;
            margin-bottom: 0;
        }
    }

    * {
        white-space: nowrap;
        font-size: 12px;
    }
}

.domtab {
    .tab-content {
        padding: 0;

        .tab-pane {
            padding-top: 0;
        }
    }
}

.innerdomtab {
    a {
        background: #6b6b6b;
    }

    a.active {
        background: #3b3b3b !important
    }

    .tab-content {
        padding: 0 !important;

        .tab-pane {
            padding-top: 0;
        }
    }
}

.input-group.themegroup {
    height: 30px;

    input {
        height: 30px;
    }

    .dropdown-menu {
        padding: 0;

        a {
            margin: 0;
            border-radius: 0;
        }
    }
}

@media(max-width:1199px) {
    .neworderbtn {
        position: absolute;
        right: 26px;
    }

    .calc_width {
        margin-top: 46px !important;
    }

    .bottom-nav {
        // position: unset;
    }

    .sidbaracc.accordion .scrolablediv {
        height: auto;
    }

    .sidbaracc.accordion .scrolablediv.scroll_heig_need {

        height: calc(100vh - 395px) !important;
    }
}

@media(max-width: 991px) {
    // .trand-cont .chartcontainer {
    //     height: 448px;
    // }

    .darkbox.h-100-calc-full.new-drop-bx {
        min-height: calc(100% - 41px) !important;
    }

    .mw-610 {
        min-width: 100%;
    }

    .leftmenu {
        max-width: 818px;
        overflow-x: auto;
        scrollbar-color: #63b2ff #c2d2e4;
        scrollbar-width: thin;
    }

    .userPages.tradePages {
        padding-left: 5px;
        padding-right: 5px;
    }
}

@media(max-width:575px) {
    .provider {
        width: 80%;
    }
}

@media(max-width:425px) {
    .provider {
        width: 80%;
    }
}

body {
    scrollbar-color: #63b2ff #c2d2e4;
    scrollbar-width: thin;
}

::-webkit-scrollbar {
    scrollbar-color: #63b2ff #c2d2e4 !important;
    scrollbar-width: thin !important;
    height: 5px;
    width: 5px;
}

::-webkit-scrollbar-track-piece {
    background-color: #c2d2e4;

}

html {
    /* For Firefox */
    overflow-y: scroll;
    scrollbar-color: #63b2ff #c2d2e4;
    scrollbar-width: thin;
}

/* For Chrome and other browsers except Firefox */
body::-webkit-scrollbar {
    width: 0.5em;
    background-color: #63b2ff;
}

body::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:vertical {
    height: 5px;
    background-color: #c2d2e4;
}

.inchart {
    position: absolute;
    right: 71px;
    top: 44px;

}

.inchartright {
    position: absolute;
    right: 20px;
    top: 44px;

}

.chartnav button.active,
.chartnav button:hover {
    background: #6d6b6b !important;
}

.themetab .nav-tabs {
    max-width: calc(100% - 2px);
    overflow-y: hidden;
    margin: auto;
}

.rightpop {
    position: absolute;
    left: -18px;
    z-index: 9;
    padding: 6px;
}

.expandcont .graybox.domtab.graytab {
    height: calc(100vh - 62px);
}

.centerdiv {
    height: 113px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.text-center {
    text-align: center !important;
}

.phoneinput input {
    margin-left: 40px !important;
    width: calc(100% - 40px) !important;
}

.phoneinput .flag-dropdown {
    background-color: transparent !important;
}

.apexcharts-tooltip.apexcharts-theme-dark {
    visibility: hidden;

    &.apexcharts-active {
        visibility: visible !important;
    }
}
.bottombtn {
    position: absolute;
    top: 21px;
    right: 23px;
    margin: auto;
    width: 30px;
  }
.chartheight {
    height: calc(100% - 23px);
}
.nodata{
    display: grid;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    // vertical-align: middle;
}
.disabled{
    opacity: 0.3;
    
}
.noevent{
    pointer-events: none;
}
.fw-bold{
    font-weight: bold !important;
}
.copyscroll  .apexcharts-tooltip.apexcharts-theme-light {
    display: none !important;
}
.nodata_found_txt{
           width: 100% !important;
           text-align: center;
           vertical-align: middle;
    
     }
     .whole_datasub_txt{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
     }
// .nodata_found_align{
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     height: auto;
//     border: 1px solid #fff;
//     margin-right: 10px;
//     .nodata_found_txt{
//         width: unset !important;

//     }
// }
