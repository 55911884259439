@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
body{
    font-family: 'Roboto' !important;
}

.homepage{
    background-color: #030d4a !important;
}
input[type="date"]::-webkit-calendar-picker-indicator {
color: #ffff !important;
}
.container{
    max-width: 90% !important;
}
.banner_sec{
  padding-top: 120px;
}
.fullban{
  /* height: 100vh; */
}
.banner_sec p{
    margin-bottom: 0px !important;
}
.dis_flex{
    display: flex !important;
    /* align-items: center !important; */
}
.banner_sec .banner_top{
    font-size: 13px;
    color: var(--themeclr);
    font-weight: 500;
    /* text-transform: uppercase; */
  }
.banner_sec .banner_title{
    font-size: 30px;
    color: #fff;
    font-weight: 500;
    line-height: 45px;
    /* text-transform: uppercase; */
}
.banner_sec .banner_title span
{
  color: var(--themeclr);

}
.banner_sec .banner_para{
    font-size: 16px;
    color: var(--themeclr);
    font-weight: 400;
    line-height: 30px;
    text-align: justify;
}
.tabcarousel {
  background: #051972;
  padding: 22px;
  border-radius: 22px;
}
.tabcarousel .owl-carousel button {
  background: transparent;
  color: #c1c1c1;
  font-weight: 400;
  font-size: 17px;
  border-color: transparent;
}
.banner_sec .card{
  padding: 5px;
    border-radius: 10px;
    /* width: 250px; */
    margin: 5% 0%;
    background-image: linear-gradient(130deg, #029cde 50% , #1b56bc 90%);
    border: #faebd700;
    cursor: pointer;
  }
  .banner_sec .owl-item{
    /* margin-right: unset !important; */
  }
  .circlearrow-left{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: #020c49;
    height: 30px;
    height: 30px;
    width: 30px;
    text-align: center;
    position: absolute;
    top: 28px;
    left: -55px;
  }
  .circlearrow-left:hover {
   
    background-color: #029cde !important;
    box-shadow: 0 0 22px 44px #03286e;
  }
  .circlearrow-left:hover .fa:before{
    color: #fff !important;
    
   
  }
  .circlearrow-right:hover {
   
    background-color: #029cde !important;
    box-shadow: 0 0 22px 44px #03286e;
  }
  .coincarousel img {
    width: 50px !important;
}
  .circlearrow-right:hover .fa:before{
    color: #fff !important;
  }
  .chartbg:after {
    content: "";
    background-image: url("../images/Land/index.png");
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    background-size: 100%;
    background-repeat: no-repeat;
    right: 0;
    background-position: center;
    z-index: -1;
}
.chartbg{
  position: relative;
  z-index: 9;
}
.sec_4{
  /* content: ""; */
  background-image: url("../images/Land/why.png");
  /* position: absolute; */
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  background-size: 100%;
  background-repeat: no-repeat;
  right: 0;
  background-position: center;
  z-index: -1;
  padding-top: 50px;
  padding-bottom: 50px;
}
  .circlearrow-right{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: #020c49;
    height: 30px;
    width: 30px;
    text-align: center;
    position: absolute;
    top: 28px;
    right: -55px;
  }
  .circlearrow-right::before{
    content: "";
    background-image: linear-gradient(130deg, #029cde 50% , #1b56bc 90%);
    border-radius: 100%;
    position: absolute;
    top: -2px;
    right: 0px;
    bottom: 0;
    left: -2px;
    width: 34px;
    height: 34px;
    z-index: -2;

  }
  .circlearrow-left::before{
    content: "";
    background-image: linear-gradient(130deg, #029cde 50% , #1b56bc 90%);
    border-radius: 100%;
    position: absolute;
    top: -2px;
    right: 0px;
    bottom: 0;
    left: -2px;
    width: 34px;
    height: 34px;
    z-index: -2;

  }
  .circlearrow-right .fa:before {
    content: "\f105";
    color: #029cde;
    
}
.circlearrow-left .fa:before {
    content: "\f104";
    color: #029cde;
    
}
  .banner_sec .card-body{
    margin: 0% 0% 0% 3%;
    padding: 0% 0%;
  }
.banner_sec .card .card-body .d-flex{
    align-items: center !important;
}
  
.banner_sec .card .cardhead{
 color: #fff;
 font-size: 14px;
 font-weight: 500;
}  
.banner_sec .card .cardsubhead{
    color: #fff;
    font-size: 12px;

}
.gradient-button{
  border-radius: 5px;
  background-color: #020c49;
  border: #029cde 1px solid;
  /* width: 100px; */
  height: 45px;
  padding: 5px 15px;
  color: #fff;
  box-shadow: 0 0px 10px #029cde ;
  text-transform: uppercase;
}  
.gradient-button:hover{
    background-color: #029cde;
  }  
  .sec_2 .gradient-card img {
    width: 50px;
    height: 50px;
    aspect-ratio: 1/1;
    object-fit: contain;
  }
  .sec_3 img {
    object-fit: contain;
    height: 87px;
  }
  .tablescard tr td {
    height: 100%;
    vertical-align: middle;
}
  .tablescard thead tr th:last-child,.tablescard tbody tr td:last-child {
    text-align: right;
}
.tablescard thead tr th:nth-child(2),.tablescard thead tr th:nth-child(3),
.tablescard tbody tr td:nth-child(2), .tablescard tbody tr td:nth-child(3),
.tablescard thead tr th:nth-child(4), .tablescard tbody tr td:nth-child(4) 
  {
  text-align: center;
}
.tablescard thead tr th:nth-child(4)
{
  text-align: center !important;

}
.tabletab .table-responsive {
  padding: 0 45px;
}
.tablescard thead tr th:last-child {
  text-align: right;
}
.gradient-card{
    flex-direction: column;
    width: 130px;
    height: 130px;
    border: double 2px transparent;
    border-radius: 15px;
    background-image: linear-gradient(#020c49, #020c49), linear-gradient(179deg, #029cde 39% , #1b56bc 54%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    align-items: center;
    justify-content: center;
    display: flex;
}
.sec_2 .card_row{
    display: flex;
    gap: 30px;
}
.sec_2 .gradient-button {
      
         width: unset !important;
        padding: 5px 25px;
}
.sec_2 .banner_top{
    font-size: 13px;
    color: var(--themeclr);
    font-weight: 500;
    text-transform: uppercase;
}
.sec_2 .banner_title{
  font-size: 30px;
  color: #fff;
  font-weight: 500;
  line-height: 45px;
  /* text-transform: uppercase; */
}

.sec_2 .banner_title span{
color:var(--themeclr);
}
.sec_2 .banner_para{
    font-size: 14px;
    color:#d1d1d1;
    font-weight: 400;
    line-height: 30px;
    text-align: justify;
    padding-top: 0px;
    padding-bottom: 25px;
}
.sec_2 .gradient-card p{
    font-size: 12px;
    color: #fff;
}
.sec_3 .sec_title{
  font-size: 30px;
  color: var(--themeclr);
}
.sec_3 .sec_para{
  font-size: 16px;
  color: #d1d1d1;
}

.sec_3 .gradient-card p{
  font-size: 20px;
  line-height: 1.2;
  color: var(--themeclr);
}
.sec_3 .gradient-card{
  flex-direction: column;
  width: 100%;
  height: 180px;
  border: double 2px transparent;
  border-radius: 15px;
  background-image: linear-gradient(#020c49, #020c49), linear-gradient(179deg, #029cde 39% , #1b56bc 54%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  align-items: center;
  justify-content: space-around;
  display: flex;
  flex-direction: row;
}
.sec_3 .gradient-card .wid {
  width: 100%;
}

.sec_2  .gradient-card {
  flex-direction: column;
  width: 130px;

}
.sec_3 .card_level{
  color: #ffffff !important;
  font-size: 16px !important;
  margin-top: 5px;
}
.sec_3 .outer {
  width: 1px;
  height: 65px;
  margin: auto;
  position: relative;
  overflow: hidden;
}
.sec_3 .inner {
  position: absolute;
  width:100%;
  height: 40%;
  background:linear-gradient(130deg, #029cde 50% , #1b56bc 90%);
  top: 30%;
  box-shadow: 0px 0px 30px 20px #029cde;
}
.sec_4 .sec_title{
  font-size: 36px;
  color: var(--themeclr);
}
.sec_4 .sec_para{
  font-size: 16px;
  color: #d1d1d1;
}
.tablescard thead {
  color: #fff;
}
.tablescard tbody tr {
  border-bottom: 1px solid #183269 !important;
}
.sec_4 .usersize{
  font-size: 50px;
  color: var(--themeclr);
}
.sec_4 .username{
  font-size: 16px;
  color: #dbd9e8;
}
.sec_5 .form-control{
  border: 1px solid #7077a3;
  background-color: #fff0;
  color: #fff;
  border-radius: 5px;
  padding: 8px;
}
.sec_5 .form-control::placeholder{
  color: #7d90f0;
  font-size: 12px;
}
.sec_5 .sec_title{
  font-size: 30px;
  color: var(--themeclr);
}
.sec_5 .sec_para{
  font-size: 16px;
  color: #d1d1d1;
  line-height: 25px;
}
.sec_5 .gradient-button{
  border-radius: 6px;
  background-color: #579aeb;
font-size: 15px;
  border: #579aeb 1px solid;
  width: auto;
  padding: 10px 25px !important;
  color: #fff;
  box-shadow: none;
  text-transform: uppercase;
}  
.sec_5 .gradient-button:hover{
  background-color: #020c49;
  box-shadow: 0 0px 10px #029cde;
  } 
  .sec_5 .sec_address{
    font-size: 16px;
    color: #fff;
  }
  .footer-text p{
    line-height: 22px;
    font-size: 12px;
  }
  .sec_5 .sec_address a{
    color: #848ecd !important;
  }
  .sec_5 .sec_address span{
    color: #848ecd;
    margin-left: 10px;

  }
  .sec_5 .sec_small_para{
    font-size: 14px;
    color: #fff;
    line-height: 1.5;
  }
  .sec_5 .sec_social{
    color: #fff;
    font-size: 20px;
    font-weight: 500;
  }
  .sec_5 .sec_social span i{
    margin: 10px;
  }
  .sec_5 .sec_social span i:hover{
   color: #029cde;
  }
  .homepage_footer{
    color: #fff;
    text-align: center;
    font-size: 12px;
  }
  .homepage .footer_hr {
    background: #183269 !important;
    height: 0.1px;
  }
  .homepage_footer p{
    font-size: 12px;
  }
  .sec_1 .table_card{
    border-radius: 10px;
    width: 100%;
    margin: 5% 0%;
    background:#051972;
    border: #faebd700;
    box-shadow: 0 0px 10px #004665 ;

  }
  .sec_1 .nav-pills{
    justify-content: center;
    padding: 15px;
  }
  .sec_1 .nav-link{
    color: #8085ca;
  }
  .sec_1 .nav-pills .nav-link.active, .sec_1 .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #083b97;
    border-radius: 50px;
  }
  .sec_1 .table_card .tablescard th{
    color: #fff ;
  }
  .sec_1 .table_first .cardhead  {
    margin-bottom: 0px;
    color: #8085ca;
    font-weight: 500;
  }
  .sec_1 .table_first {
    display: flex;
    align-items: center;
  }
  .red_badges{
    background-color: #6c2044 !important;
    color: #d15775;
    /* margin-top: 7px; */
    padding: 14px 22px;
    font-size: 14px;
    border-radius: 8px;
  }
  .tablescard thead tr {
    /* text-align: center; */
    font-size: 18px;
}
.themetabs .nav ,.tablescard  th{
  border-bottom: 1px solid #183269 !important;
}
.themetabs .nav a.active, .themetabs .nav a:hover, .themetabs .nav a:focus {
  background-color: #073a95 !important;
  border-radius: 26px;
  border: none;
  color: #fff !important;
}
.themetabs .nav a {
  color: #5c6caf !important;
  padding: 15px 20px;
}
.tablescard td {
  color: #fff;
}
.themetabs .nav {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-color: #63b2ff #c2d2e4;
scrollbar-width: thin;
  padding-bottom: 15px;
}
  .green_badges{
    background-color: #247645 !important;
    color: #76cf76;
    /* margin-top: 7px; */
    padding: 14px 22px;
    font-size: 14px;
    border-radius: 8px;
  }
  div#tabcarousel,.tablescard thead {
    border-bottom: 1px solid #183269 !important;
  }
  /* p.sec_para {
    text-transform: uppercase;
  } */
  .sec_social svg {
    width: 32px;
    height: 20px;
    fill: #fff !important;
    padding: 0 6px;
  }
  .sec_social svg:hover {
    fill: var(--themeclr) !important;
  }
  @media(max-width:991px){
    .sec_4{
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }
  @media(max-width:767px){
    .sec_3 .gradient-card p
    {
      font-size: 16px !important;
    }
    .sec_4 .usersize {
      font-size: 30px;
    }
    .sec_4 .username {
      font-size: 14px;
    }
    .tabletab .table-responsive {
      padding: 0 ;
    }
    .sec_3 .card_level{
      font-size: 14px !important;
    }
    .sec_3 .card_num{
      font-size: 20px;
    }
  }
  @media(max-width:575px){
    .sec_2 .banner_title,.banner_sec .banner_title
    {
      font-size: 20px !important;
      line-height: 30px !important;

    }
    .sec_5 .sec_title,.sec_4 .sec_title,.sec_3 .sec_title
    {
      font-size: 20px !important;
    }
    .sec_5 .sec_para
    {
      font-size: 14px !important;

    }
    .sec_5 .sec_address
    {
      font-size: 15px !important;
      display: flex;
      flex-direction: column;
    }
    .sec_5 .sec_address span
    {
      margin-left: 0px !important;
    }
    .sec_2 .gradient-card{
      width: 130px;
      height: 115px;
    }
    .sec_2 .card_row{
      gap:20px !important
    }
    .sec_3 .gradient-card p{
      font-size: 20px;
    }
  }
  @media(max-width:425px){
    .sec_2 .card_row{
      display: grid;
      grid-template-columns: 50% 50% ;
    }
  }

  .themetabs .nav a
{
  display: flex;
    align-items: center;
    justify-content: center;
}
.tabletab.table_home .table-responsive
{
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.tabletab.table_home .table-responsive tbody tr td
{
  border-top: 1px solid #183269 !important;
  font-size: 14px !important;
  color:#7c87c9 !important;
}
.light_theme .table-responsive tbody tr td p.cardhead
{
  font-size: 14px !important;
  color:#7c87c9 !important;
  font-weight: 500 !important;
}
.tabletab.table_home .table-responsive tr th{
font-size: 15px !important;
}
.homr_table_text_red
{
  font-size: 14px !important;
  color:#dc4d62 !important;
  font-weight: 500 !important;
}
.homr_table_text_green
{
  font-size: 14px !important;
  color:#7be34a !important;
  font-weight: 500 !important;
}
.tablescard
{
  margin-top: 15px !important;
}
/* .row_trans_grad .gradient-card
{
  background: transparent !important;
} */

.btn_sec_banner
{
  display: flex;
}
.coincaro_btn a:hover .card
{
  background-image: linear-gradient(130deg, #1b56bc 50% , #029cde 90%) !important;
}
.card_num
{
  margin-bottom: 20px;
}
/* .card_level
{
  text-align: left !important;
} */
.sec_5
{
  background-image: url("../images/Land/why.png");
  /* position: absolute; */
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  background-size: 100%;
  background-repeat: no-repeat;
}
.blue_text_hightlihght
{
  color: var(--themeclr);
}
.form_row_home_contact
{
  /* margin-top: 60px; */
  margin-top: 30px;
}

.tablescard tr td.text_red_change
{
  color:#d15775 !important;
}
.tablescard tr td.text_green_change
{
  color:#76cf76 !important;
}

@media only screen and (min-width:768px) and (max-width:991px)
{
  .banner_sec .card .cardhead
  {
    font-size: 13px !important;
    min-height: 41px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .banner_sec .card .p-3
  {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .banner_sec .card 
  {
    min-height: 78px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (min-width:992px) and (max-width:1199px)
{
  .banner_sec .card .cardhead
  {
    font-size: 13px !important;
    min-height: 41px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.light_theme .fie_inout_land label.custom-file-label

{
  color: #7d90f0 !important;
  font-size: 12px;
  border: 1px solid #7077a3 !important;
    background-color: #fff0 !important;
}

.light_theme .custom-file.fie_inout_land input,
.light_theme .custom-file.fie_inout_land
{
  border:none !important;
  border-radius: 0px !important;
}
.light_theme .fie_inout_land .custom-file-label small

{
  color: #7d90f0 !important;
  font-size: 12px;
  text-transform: uppercase !important;
}
.light_theme .custom-file.fie_inout_land input
{
  top: 0px;
    position: absolute;
    left: 0px;
    cursor: pointer;
}

.theme_select_new_dropdown
{
  background: transparent !important;
    color: #fff;
    min-height: 35px !important;
    border-radius: 4px;
    padding-left: 10px !important;
    padding-right: 10px !important;
    border-color: #404040 !important;
    background-color: transparent !important;
    border-color: #404040 !important;
    border-radius: 4px !important;
    color: #787272 !important;
    box-shadow: 0 0 0 0rem #fff !important;
    height: 35px !important;
    font-size: 12px !important;
    border: 1px solid #787272 !important;
    padding: 10px;
}

.theme_select_new_dropdown_only_border
{
  background: transparent !important;
    color: #fff;
    min-height: 35px !important;
    border-radius: 0px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    border-color: #787272 !important;
    background-color: transparent !important;
    border-color: #787272 !important;
    border-radius: 0px !important;
    color: #AAAAAA !important;
    box-shadow: 0 0 0 0rem #fff !important;
    height: 35px !important;
    font-size: 14px !important;
    border-bottom: 1px solid #787272 !important;
    border-top:none !important;
    border-left: none !important;
    border-right: none !important;
    /* padding: 10px; */
}


.roi_minus
{
  color:#f05230 !important;
}
.roi_plus
{
  color:#068545 !important;
}
.custdacc.inneracc p
{
  font-size: 12px !important;
}

.btn-outline-secondary.inac_sec_btn
{
  opacity: 0.5 !important;
}

.input_date_new_pick,.input_date_new_pick:hover,.input_date_new_pick:focus
{
  background-color: rgb(83, 83, 83);
    border-color: #292929 !important;
    font-size: 12px !important;
    padding: 0px 4px;
    appearance: auto;
    border-radius: 5px;
    background-image: none;
    height: 25px;
}
input[type="date"]:after
{
  background-size: 12px !important;
  padding-right: 0px !important;
}

.input_date_new_pick.input_date_new_pick_modal,
.input_date_new_pick.input_date_new_pick_modal:hover,
.input_date_new_pick.input_date_new_pick_modal:focus


{
background: #292929 !important;
}
.rdtPicker
{
  bottom:40px;
  /* margin-top: -140%; */
  right:0px;
}
.rdt_new input,.rdt_new input:hover, .rdt_new input:focus

{
  border-radius: 5px;
    height: 26px;
    background-image: url("../images/Land/calendar_icon.png") !important;
    background-repeat: no-repeat;
    background-size: 14px !important;
    background-position: right 7px top 6px;
    padding-left: 5px !important;
    font-size: 12px !important;
    background-color: rgb(83, 83, 83) !important;
}
.rd_new_upload_date.rdt_new input,
.rd_new_upload_date.rdt_new input:hover,
.rd_new_upload_date.rdt_new input:focus
{
  background-color: transparent !important;
}
@media only screen and (min-width:768px) and (max-width:991px)
{
  .rdtPicker
  {
    max-width: 216px !important;
  }
}

.rdtPicker th,.rdtPicker td
{
  color:#000 !important;
}

.rdt_new.rdt_new_ro_mod input,.rdt_new.rdt_new_ro_mod input:hover, .rdt_new.rdt_new_ro_mod input:focus

{
  border-radius: 5px;
    height: 26px;
    background-image: url("../images/Land/calendar_icon.png") !important;
    background-repeat: no-repeat;
    background-size: 14px !important;
    background-position: right 7px top 6px;
    padding-left: 5px !important;
    font-size: 12px !important;
    background-color: #292929 !important;
}

.rdt_new input:disabled
{
  background-repeat: no-repeat !important;
  background-size: 14px !important;
  background-position: right 7px top 6px !important;
}
/* @media only screen and (max-width:575px)
{
  .rdtPicker
  {
    margin-top: -155% !important;
  }
} */

.apexcharts-xaxis, .apexcharts-yaxis
{
  display: block !important;
}
/* .apexcharts-xaxistooltip, .apexcharts-yaxistooltip */
.apexcharts-tooltip
{
  opacity: 1 !important;
  display: block !important;
  /* color:#000 !important; */

}
.graytab + .tab-content .apexcharts-tooltip div
{
padding: unset !important;
}
.graytab + .tab-content .apexcharts-tooltip
{
  padding: 5px !important;
}
.error-message
{
  font-size: 12px !important;
}
.error-message.text-white
{
  color:#fff !important;
}
a.page-link:hover
{
  color:#fff !important;
}