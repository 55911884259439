:root{
  --themeclr: #78c5ef
}


.header_page .nav {
    position: fixed;
    /* height: 50px; */
    width: 100%;
    background-color:#4d4d4d00;
    /* position: relative; */
    padding-top: 10px;
    justify-content: space-between;
    z-index: 999;
    left: 0;
    right: 0;
    padding-left: 80px;
    padding-right: 80px;
  }
  .header_page .nav.position_fixed{
    background-color: #01072a;
  }
  .header_page .nav > .nav-header {
    display: inline;
  }
  
  .header_page .nav > .nav-header > .nav-title {
    display: inline-block;
    font-size: 22px;
    color: #fff;
    padding: 10px 0px 10px 0px;
  }
  
  .header_page .nav > .nav-btn {
    display: none;
  }
  
  .header_page .nav > .nav-links {
    display: inline;
    float: right;
    font-size: 18px;
  }
  
  .header_page .nav > .nav-links > a {
    display: inline-block;
    padding: 13px 10px 13px 10px;
    text-decoration: none;
    color: #fff !important;
    font-size: 14px;
  }
  
  .header_page .nav > .nav-links > a:hover {
    background-color: rgba(0, 0, 0, 0.3);
    color: var(--themeclr) !important;
  }
  
  .header_page .nav > #nav-check {
    display: none;
  }
  .header_page .nav-title img {
    width: 170px;
  }
  


.close-ic {
  color: #fff;
  font-size: 50px;

  
  

}
.header_page .nav > .nav-links > a:hover{
  color: #fb8544 ;
  background-color: none !important;
  background: none !important;
}

  @media (max-width:991px) {
    .header_page .nav{
      padding-top: unset !important;
    }
    .header_page .nav > .nav-btn {
      display: inline-block;
      position: absolute;
      right: 0px;
      top: 0px;
    }
    .header_page .nav > .nav-btn > label {
      display: inline-block;
      width: 50px;
      height: 50px;
      padding-top: 30px;
    }
    .header_page .nav > .nav-btn > label:hover,.header_page .nav  #nav-check:checked ~ .nav-btn > label {
      background-color: rgba(0, 0, 0, 0.3);
    }
    .header_page .nav > .nav-btn > label > span {
      display: block;
      width: 20px;
      height: 5px;
      border-top: 2px solid #eee;
    }
    .header_page .nav > .nav-links {
      position: absolute;
      display: block;
      width: 100%;
      background-color: #01072a;
      height: 0px;
      /* transition: all 0.3s ease-in; */
      overflow-y: hidden;
      top: 50px;
      left: 0px;
    }
    .header_page .nav > .nav-links > a {
      padding: 0px 11px 10px 10px;
      display: block;
      width: 100%;
    }
    .header_page .nav > #nav-check:not(:checked) ~ .nav-links {
      height: 0px;
    }
    .header_page .nav > #nav-check:checked ~ .nav-links {
      /* height: calc(100vh - 50px); */
      height: auto;
      padding: 20px;
      margin-top: 10px;
      overflow-y: auto;
      scrollbar-color: #63b2ff #c2d2e4;
      scrollbar-width: thin;
    }
  }
  .header_page .gradient-button{
  border-radius: 5px;
  background-color: #029cde;
font-size: 12px;
  border: #029cde 1px solid;
  width: auto;
  height: 45px;
  padding: 5px 15px;
  color: #fff;
  box-shadow: 0 0px 10px #029cde ;
}  
.header_page .gradient-button:hover{
  background-color: #020c49;
  } 
  .header_page .nav > .nav-links > a.loginbtn{
    text-transform: uppercase;
    color: var(--themeclr) !important;
  }
  .header_page .nav > .nav-links > a.loginbtn:hover
  {
color:#fff !important;
  }
  .header_page .nav > .nav-links > a.loginbtn:hover svg
  {
    fill:#fff !important;
  }
  .loginbtn svg{
    height: 16px;
    width: 30px;
    fill: var(--themeclr);
  }

 @media(max-width:997px){
  .header_page .nav > .nav-links > a {
    padding: 13px 7px 13px 7px;
  }
 }
 @media(min-width:992px){
  .header_page .nav > .nav-links{
    display: flex;
    align-items: center;
  }
 }
 @media(max-width:991px){
  .header_page .nav{
    padding-left: 15px;
  }
 }